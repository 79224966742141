export default function Index({ height, fontSize }) {
  return (
    <div className='z-20 gap-1 flex items-center overflow-visible'>
      <svg className={height ? height : 'h-10'} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 205.71 205.71'>
        <path className='fill-primary' d='m198.14,205.71c-1.94,0-3.88-.74-5.36-2.22-29.71-29.71-59.97-44.78-89.92-44.78s-60.21,15.07-89.92,44.78c-2.96,2.96-7.76,2.96-10.72,0-1.48-1.48-2.22-3.42-2.22-5.36s.74-3.88,2.22-5.36c59.67-59.67,59.67-120.18,0-179.84-1.48-1.48-2.22-3.42-2.22-5.36S.74,3.7,2.22,2.22c2.96-2.96,7.76-2.96,10.72,0,29.71,29.71,59.97,44.78,89.92,44.78s60.21-15.07,89.92-44.78c2.96-2.96,7.76-2.96,10.72,0,1.48,1.48,2.22,3.42,2.22,5.36s-.74,3.88-2.22,5.36c-59.67,59.67-59.67,120.18,0,179.84,1.48,1.48,2.22,3.42,2.22,5.36s-.74,3.88-2.22,5.36-3.42,2.22-5.36,2.22Zm-95.28-62.15c18.58,0,37.09,4.91,55.36,14.66-9.75-18.28-14.66-36.78-14.66-55.36s4.91-37.09,14.66-55.36c-18.28,9.75-36.78,14.66-55.36,14.66s-37.09-4.91-55.36-14.66c9.75,18.28,14.66,36.78,14.66,55.36s-4.91,37.09-14.66,55.36c18.28-9.75,36.78-14.66,55.36-14.66Z'/>
        <polygon className='fill-black' points='102.86 73.85 80.76 108.93 102.86 108.93 102.86 131.86 124.95 96.78 102.86 96.78 102.86 73.85'/>
      </svg>
      <p className={(fontSize ? fontSize : 'text-lg') + ' font-bold text-black'}>LightSale</p>
    </div>
  )
}

import { getApp, getApps, initializeApp } from 'firebase/app'
import { getAnalytics, setUserProperties, logEvent, isSupported } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

if(!getApps().length) {
  initializeApp(firebaseConfig)
}

const app = getApp()

isSupported().then((result) => {
  if(result) {
    const analytics = getAnalytics(app)
    setUserProperties(analytics, {
      app: 'mongo-firebase'
    })
    logEvent(analytics, 'mongo_firebase_connected')
    console.log('analytics connected')
  } else {
    console.log('no analytics')
  }
})

import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { createPortal } from 'react-dom'

import { XMarkIcon } from '@heroicons/react/24/outline'

function Index({ children, onClose, center }, ref) {
  const [show, setShow] = useState(false)
  const [active, setActive] = useState(false)

  useImperativeHandle(ref, () => ({
    open: () => setShow(true),
    close: () => setShow(false)
  }))

  useEffect(() => {
    if(active && show === false && onClose) onClose()
    if(show) setActive(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const trigger = children.find(child => child.props.trigger)
  const content = children.find(child => child.props.content)

  // 52288843
  // PNa189acde86204bfd004d51c550be40e5
  return (
    <>
      {trigger}
      {createPortal(show &&
        <>
          <button
            onClick={() => {
              setShow(false)
            }}
            className='fixed z-50 top-3 right-3 group flex h-8 w-8 rounded-full bg-primary hover:brightness-110'>
            <XMarkIcon strokeWidth={1.75} className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </button>
          <div className={'flex flex-col overflow-scroll fade-in bg-black bg-opacity-40 fixed top-0 inset-0 z-40 backdrop-filter backdrop-blur-md ' + (center ? 'justify-center' : '')}>
            <div className='fixed top-0 z-0 h-full w-full' onClick={() => setShow(false)} />
            <div className='py-10vh relative z-10 mx-auto'>
              {content}
            </div>
          </div>
        </>, document.body
      )}
    </>
  )
}

export default forwardRef(Index)

import { useRef, useEffect, useContext } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, onSnapshot, orderBy } from 'firebase/firestore'

import { Context } from 'store/index'

import { AnimatePresence } from 'framer-motion'

import { sections } from 'config'

import Proposals from 'views/proposals'

import LayoutTransition from 'components/transitions/layout'

export default function Index() {
  const [state, dispatch] = useContext(Context)

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const location = useLocation()

  const mounted = useRef(null)

  const ownersUnsub = useRef(null)
  const propertiesUnsub = useRef(null)

  useEffect(() => {
    mounted.current = true
    getProperties()
    getOwners()

    return () => {
      mounted.current = false
      clearSubscriptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function clearSubscriptions() {
    if(propertiesUnsub.current) propertiesUnsub.current()
    if(ownersUnsub.current) ownersUnsub.current()
  }

  async function getOwners() {
    try {
      // console.log('get owners')
      const q = query(collection(db, 'contacts-owners'), orderBy('createdAt', 'desc'))
      ownersUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            dispatch({
              type: 'contact-owners-set',
              payload: querySnapshot.docs.sort((a, b) => a.data().firstName.localeCompare(b.data().firstName)).map(doc => ({ value: doc.id, label: doc.data().firstName + ' ' + doc.data().lastName, email: doc.data().email })) })
          }
          else dispatch({ type: 'contact-owners-set', payload: null })
        }
      })
    } catch(e) {
      console.log(e)
    }
  }

  async function getProperties() {
    try {
      // console.log('get properties')
      const q = query(collection(db, 'contacts-properties'), where('name', 'in', ['lead_source', 'hs_lead_status', 'service_area', 'lifecyclestage', 'qualified_roof_shading', 'roof_complexity', 'lead_return_reason', 'call_disposistions']))
      propertiesUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            const properties = {}
            querySnapshot.docs.forEach(doc => {
              properties[doc.id] = doc.data().options
            })
            dispatch({ type: 'contact-properties-set', payload: properties })
          } else dispatch({ type: 'contact-properties-set', payload: null })
        }
      })
    } catch(e) {
      console.log(e)
    }
  }

  const routes = Object.entries(sections).map(([key, value]) => {
    return value
  }).map(section => section.paths.map(path =>
    <>
      <Route path={path.path} element={path.element} />
      {path.children && path.children.map(route =>
        <Route key={path.path + route.path} path={path.path + route.path} element={route.element} />
      )}
    </>
  ))

  return (
    <LayoutTransition>
      <AnimatePresence exitBeforeEnter mode='wait'>
        <Routes key={location.pathname + '-routes-authenticated'} location={location}>
          <Route path='/' element={<Proposals />} />
          {routes}
        </Routes>
      </AnimatePresence>
    </LayoutTransition>
  )
}

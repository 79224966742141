const Reducer = (state, action) => {
  switch (action.type) {
    case 'toast-set':
      return {
        ...state,
        toast: action.payload
      }
    case 'user-set':
      return {
        ...state,
        user: action.payload
      }
    case 'settings-set':
      return {
        ...state,
        settings: action.payload
      }
    case 'profile-set':
      return {
        ...state,
        profile: action.payload
      }
    case 'contact-properties-set':
      return {
        ...state,
        contactProperties: action.payload
      }
    case 'contact-owners-set':
      return {
        ...state,
        contactOwners: action.payload
      }
    default:
      return state
  }
}

export default Reducer

import { useRef, useEffect } from 'react'
import Logo from 'components/logo/logo'

import { ArrowRightIcon } from '@heroicons/react/24/outline'

import { motion, useAnimationControls } from 'framer-motion'

export default function Index({ project, proposals, contact }) {
  const ref = useRef(null)
  const animation = useAnimationControls()

  useEffect(() => {
    const options = {
      rootMargin: '0px 0px -360px 0px'
    }

    const observer = new IntersectionObserver(e => {
      if(e[0].isIntersecting) {
        animation.start('visible')
        observer.disconnect()
      }
    }, options)

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div data-fill-class='bg-primary-350' className='px-6 py-24 flex flex-col items-center justify-center'>
      <motion.div
        className='text-center'
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, duration: .4 }}>
        <p className='font-mono uppercase mb-4'>What's Next</p>
        <h1 className='hidden font-body font-bold text-4xl max-w-3xl mb-12'>Sign your proposal &mdash; your one step closer to <span className='relative inline-block'><span className='z-10 relative'>making you're home shine</span><span className='inline-block absolute bottom-0 -mb-1 left-0 w-full h-11 bg-primary-150 rounded-lg'></span></span> with solar.</h1>
        <h1 className='font-body font-bold text-4xl max-w-3xl mb-12'>Sign your proposal &mdash; your one step closer to <span className='underline'>making you're home shine with solar</span>.</h1>

        <div className='text-center mb-12'>
          <a className='group inline-flex items-center bg-white gap-3 justify-center px-6 py-2 rounded-full font-bold' href={project.webQuoteUrl} target='_blank'>
            View & Sign My Proposal
            <ArrowRightIcon className={'group-hover:translate-x-2 transition duration-200 h-4.5 w-4.5 m-auto transform'} strokeWidth={2.25} />
          </a>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}>
        <div ref={ref} className='mx-auto max-w-sm aspect-square relative rounded-full mb-12'>
          <div className='w-56 transform -translate-y-1/2 -translate-x-3 absolute top-1/2 right-full'>
            <motion.div
              initial={{ x: 20, opacity: 0 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 20, opacity: 0 }
              }}
              animate={animation}
              transition={{ x: { type: 'spring', stiffness: 200 }, duration: .4 }}
              className='text-sm px-3 pt-3 pb-4 relative border-2 border-black rounded-2xl h-full w-full'>
              <span className='transform -translate-y-1/2 translate-x-full h-0 w-0 border-y-8 border-y-transparent border-l-[12px] border-l-black absolute top-1/2 right-0'></span>
              <p className='font-bold mb-2'>{contact.formatted_address}</p>
              <p>This is what your custom solar system could look like.</p>
            </motion.div>
          </div>

          <div>
            <img loading='lazy' alt='Solar installation design aerial view.' className='block h-full w-full object-cover rounded-full overflow-hidden' src={proposals[0].drawing.imageUrl} />
          </div>
        </div>
      </motion.div>
    </div>
  )
}

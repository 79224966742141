import { Fragment, useRef, useState, useEffect } from 'react'

import { useInView } from 'react-intersection-observer'
import { motion, useAnimationControls, useScroll, AnimatePresence } from 'framer-motion'

import { ArrowRightIcon, PlusIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

export default function Index({ contact, project, proposals }) {
  const ref = useRef(null)
  const animation = useAnimationControls()

  const [selectedTab, setSelectedTab] = useState(proposals[0])

  useEffect(() => {
    const options = {
      rootMargin: '0px 0px -360px 0px'
    }

    const observer = new IntersectionObserver(e => {
      if(e[0].isIntersecting) {
        animation.start('visible')
        observer.disconnect()
      }
    }, options)

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div className='flex flex-col items-center px-6 py-24'>
      <div>
        <motion.div
          className='text-center'
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ y: { type: 'spring', stiffness: 100 }, duration: .4 }}>
          <p className='font-mono uppercase mb-8'>Solar Proposal Breakdown</p>
          <h1 className='font-body font-bold text-4xl max-w-3xl mb-11'>Your proposal provides you with a comprehensive overview of <span className='text-primary'>your solar system</span>.</h1>
        </motion.div>
      </div>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}>
        <div>
          <div className='text-center'>
            <ViewSign project={project} />
            <p className='text-lg max-w-lg mt-9 mb-12 text-center'>Below is a quick breakdown of your solar proposal &mdash; click the button above to see your full proposal in detail.</p>
          </div>
        </div>

        <div ref={ref} className='mx-auto max-w-sm aspect-square relative rounded-full mb-12'>
          <div className='w-56 transform -translate-y-1/2 -translate-x-3 absolute top-1/2 right-full'>
            <motion.div
              initial={{ x: 20, opacity: 0 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 20, opacity: 0 }
              }}
              animate={animation}
              transition={{ x: { type: 'spring', stiffness: 200 }, duration: .4 }}
              className='text-sm px-3 pt-3 pb-4 relative bg-primary-200 rounded-2xl h-full w-full'>
              <span className='transform -translate-y-1/2 translate-x-full h-0 w-0 border-y-8 border-y-transparent border-l-[12px] border-l-primary-200 absolute top-1/2 right-0'></span>
              <p className='font-bold mb-2'>{contact.formatted_address}</p>
              <p>This is what your custom solar system could look like.</p>
            </motion.div>
          </div>
          <div
            className='absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-full pl-3'>
            <motion.p
              initial={{ x: -20, opacity: 0 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -20, opacity: 0 }
              }}
              animate={animation}
              transition={{ x: { type: 'spring', stiffness: 200 }, delay: .4, duration: .4 }}
              className='border-2 border-gray-150 relative font-bold text-xl bg-gray-150 rounded-lg px-4 pb-2.5 pt-2'>
              <span className='transform -translate-y-1/2 h-0 w-0 border-y-8 border-y-transparent border-r-[12px] border-r-gray-150 absolute top-1/2 right-full'></span>
              <span>Price</span> &mdash; ${proposals[0].solarPrice.toLocaleString()}
            </motion.p>
          </div>

          <div>
            <img loading='lazy' alt='Solar installation design aerial view.' className='block h-full w-full object-cover rounded-full overflow-hidden' src={proposals[0].drawing.imageUrl} />
          </div>
        </div>
      </motion.div>

      <motion.div
        className='w-full max-w-xl'
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, delay: .4, duration: .4 }}>
        {proposals.length > 1 &&
          <h2 className='font-bold text-3xl mb-12 text-center max-w-lg mx-auto'>
            You've got a {proposals.length > 2 ? 'few' : 'couple'} <span className='text-primary'>custom system design options</span> to choose from.
          </h2>
        }
        {proposals.length === 1 &&
          <h2 className='font-bold text-3xl mb-12 text-center'>
            Here's your <span className='text-primary'>custom design</span> specifications.
          </h2>
        }
        {/* <Options proposals={proposals} project={project} /> */}
        {proposals && proposals.length > 1 &&
          <nav>
            <div className='flex gap-2 justify-center'>
              {proposals.map((proposal, index) => (
                <button
                  key={proposal.name}
                  className={'focus:outline-none relative block rounded-full px-5 py-1.5 font-bold ' + (proposal.name === selectedTab.name ? 'bg-primary' : 'bg-gray-150')}
                  onClick={() => setSelectedTab(proposal)}>
                  Option 0{index + 1}
                  {proposal.name === selectedTab.name ? (
                    <div className='absolute top-full left-1/2 transform -translate-x-1/2 block w-[10px]'>
                      <motion.div
                        initial={{ y: -6, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -6, opacity: 0 }}
                        transition={{ y: { duration: .3 }, opacity: { duration: .3 } }}
                        className='block h-0 w-[8px]'>
                        <div className='block h-0 w-0 border-x-[8px] border-x-transparent border-t-[8px] border-t-primary' />
                      </motion.div>
                    </div>
                  ) : null}
                </button>
              ))}
            </div>
          </nav>
        }
        <div>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={selectedTab ? selectedTab.name : 'empty'}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 0, opacity: 0 }}
              transition={{ y: { type: 'spring', stiffness: 200 }, duration: .4 }}>
              <Option proposal={selectedTab} project={project} />
            </motion.div>
          </AnimatePresence>
        </div>
      </motion.div>
    </div>
  )
}

function ViewSign({ project }) {
  return (
    <a className='group inline-flex items-center gap-3 justify-center px-6 py-2 rounded-full bg-primary font-bold' href={project.webQuoteUrl} target='_blank'>
      View & Sign My Proposal
      <ArrowRightIcon className={'group-hover:translate-x-2 transition duration-200 h-4.5 w-4.5 m-auto transform'} strokeWidth={2.25} />
    </a>
  )
}

function Option({ proposal, project }) {
  return (
    <div className='pt-6 relative'>
      <div className='hidden relative left-1/2 transform -translate-x-1/2 block h-0 w-0 border-x-8 border-x-transparent border-b-[12px] border-b-primary-300'></div>
      <div className='border-2 border-gray-200 rounded-xl px-3 pt-6 pb-12 space-y-12'>
        <div className='relative flex flex-col items-center gap-12'>
          <div>
            <h3 className='hidden font-bold text-2xl mb-8 text-center mx-auto'>{proposal.name}</h3>
            <div className='relative'>
              <div className='mr-3 transform -translate-y-1/2 1translate-x-3 absolute top-1/2 right-full'>
                <motion.div
                  initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ x: { type: 'spring', stiffness: 200 }, delay: .6, duration: .4 }}
                  className='text-lg leading-tight whitespace-nowrap px-2 pt-2 pb-3 w-32 relative bg-primary rounded-lg'>
                  <span className='transform -translate-y-1/2 translate-x-full h-0 w-0 border-y-8 border-y-transparent border-l-[12px] border-l-primary absolute top-1/2 right-0'></span>
                  <span className='font-bold block'>Price</span>
                  <span className='block'>${proposal.solarPrice.toLocaleString()}</span>
                </motion.div>
              </div>
              <img loading='lazy' alt='Solar installation design aerial view.' className='block bg-primary-20000 w-56 h-56 object-cover rounded-full overflow-hidden' src={proposal.drawing.imageUrl} />
            </div>
          </div>

          <h2 class='font-bold text-xl text-left sm:text-center sm:max-w-lg m-auto'>Your new system will offset the amount of electricity you need from your utility company.</h2>

          <ul className='text-center flex flex-wrap w-full px-6'>
            <li className='flex-1'>
              <span className='block text-xs font-mono uppercase'>Offset</span>
              <span className='block font-bold text-3xl'>{proposal.consumptionOffset}<sup className='text-base'>%</sup></span>
            </li>
            <li className='flex-1'>
              <span className='block text-xs font-mono uppercase'>Production</span>
              <span className='block font-bold text-3xl'>{Math.round(proposal.production.acAnnual).toLocaleString()} <sup className='text-base'>kWh</sup></span>
            </li>
            <li className='flex-1'>
              <span className='block text-xs font-mono uppercase'>System Size</span>
              <span className='block font-bold text-3xl'>{proposal.sizeInKwCecAc.toLocaleString('en', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} <sup className='text-base'>kW</sup></span>
            </li>
          </ul>
        </div>

        <div className='flex items-end text-center'>
          <ul className='grid grid-cols-2 gap-2 text-sm flex-1'>
            {proposal.materials && proposal.materials.sort((a,b) => b.materialType.localeCompare(a.materialType)).map((material, index) =>
              <li key={index + '-materials'}>
                <span className='block truncate font-bold text-lg mb-1'><span className='capitalize'>{material.materialType}</span>s</span>
                <span className='block truncate'>{material.name}<br />
                <span className='text-gray-500'>Quantity &mdash; {material.quantity}</span></span>
              </li>
            )}
          </ul>
        </div>

        <div class='bg-gray-150 rounded-2xl w-full px-3 py-3'><div class='flex-1 mb-8'><p class='font-bold text-lg'>18 month no payment — 25 year 3.99%</p></div><div class='flex-1'><div class='flex w-full pb-3'><p><span class='font-bold'>Estimated payment — </span>$224.25/mo</p></div><p class='text-xs text-gray-700 max-w-sm'>Loan payment assumes the 30% ITC/ Solar Tax Credit is applied to the loan prior to the 18 month re-am period.</p></div></div>

        <div className='text-center'>
          <ViewSign project={project} />
        </div>

        <ul className='flex gap-3 justify-center'>
          <li>Monthly Bill</li>
          <li>Estimated Savings</li>
          <li>Monthly Offset</li>
        </ul>

        <div className='bg-primary-200 rounded-2xl aspect-[16/7] w-full'>
        </div>

        <h3 className='text-xl font-bold text-left sm:text-center sm:max-w-xl m-auto'>Did you know — dirty energy <span className='text-gray-400'>(the utitlity company)</span> is costing you 3% more every year?</h3>
        <p className='sm:text-center'>Utility residential electricity prices have risen steadily in the last decade. According to the Energy Information Administration, residential electricity rates have increased nationally by around 15% in the last 10 years</p>
        <p className='sm:text-center'>That's an increase of a little more than 0.2¢/year!</p>
        <h3 className='text-xl font-bold text-left sm:text-center sm:max-w-md m-auto'>With that in mind, these are your estimated costs of <span className='bg-secondary px-2 rounded-lg'>not going solar</span>.</h3>

        <div className='bg-primary-200 rounded-2xl aspect-[16/7] w-full'>
        </div>

        <p class='sm:text-center max-w-md mx-auto'>Whether or not you go solar &mdash; you are going to <span class='font-bold'>spend money to keep your lights on</span>.</p>
        <p class='sm:text-center'>At your current average costs of $175/month you will pay the electric company around $24,635.93 over the next 10 years with nothing gained.</p>

        <h3 className='text-xl font-bold text-left sm:text-center max-w-lg m-auto'>Enjoy the benefits of going solar at a lower cost than what you will pay your electric company.</h3>
        <ul className='grid sm:grid-cols-2 gap-6 w-full'><li className='p-3 pb-4 bg-primary-200 rounded-xl'><p className='font-bold pb-3'>Energy owership</p><p className='flex-1 text-sm'>Own your energy supply and add value to your home versus renting from the electric company with nothing to show for it.</p></li><li className='bg-primary-200 p-3 pb-4 rounded-xl'><p className='font-bold pb-3'>Monthly budget</p><p className='flex-1 text-sm'>Protect your monthly budget from inflation and continuous utility rate increases.</p></li><li className='bg-primary-200 p-3 pb-4 rounded-xl'><p className='font-bold pb-3'>Energy security</p><p className='flex-1 text-sm'>Solar + Storage provides a safe and effective way to ensure your family will maintain electricity supply should the grid go down.</p></li><li className='bg-primary-200 p-3 pb-4 rounded-xl'><p className='font-bold pb-3'>Carbon footprint</p><p className='flex-1 text-sm'>Reduce your personal carbon footprint and reliance on fossil fuels.</p></li></ul>

        <h2 className='font-bold text-xl text-lefft sm:text-center mb-6 sm:max-w-md m-auto'>Here are a few environmental impacts of your new solar system.</h2>
        <ul className='flex gap-3 justify-center'>
          <li>Coal Emissions</li>
          <li>Carbon Offset</li>
          <li>Gas Offset</li>
        </ul>
        <div className='rounded-2xl'>
          <div>
             <div className='bg-primary-200 rounded-2xl px-3 py-3'>
                <div className='flex items-center gap-6'>
                  <span data-aos='flip-left' data-aos-offset='200' data-aos-delay='0' className='shrink-0 inline-flex inline-block h-20 w-20 rounded-full bg-primary aos-init aos-animate'>
                     <span className='m-auto'>
                        <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' className='h-9' viewBox='0 0 359.5 383.34'>
                           <path d='M9.08,383.34C2.41,380.52-.1,375.59,0,368.3c.31-22.45,.11-44.91,.11-67.36,0-8.78,4.59-13.38,13.32-13.4,3.61,0,7.21,0,11.22,0,.56-7.55,1.15-14.95,1.65-22.35,1.71-25.37,3.41-50.75,5.08-76.12,1.35-20.4,2.64-40.8,3.97-61.2,.21-3.23,.39-6.48,.76-9.69,.64-5.51,5.55-10.12,11.16-10.22,8.48-.15,16.97-.16,25.45,0,5.78,.11,10.73,4.87,11.14,10.64,1.33,18.9,2.52,37.81,3.8,56.72,1.39,20.52,2.83,41.04,4.22,61.56,1.08,15.92,2.12,31.84,3.18,47.76,.06,.85,.12,1.69,.19,2.72h37.24c.79-11.47,1.59-22.76,2.35-34.05,1.44-21.27,2.86-42.54,4.28-63.81,1.1-16.54,2.15-33.09,3.25-49.63,.47-7.09,.92-14.18,1.51-21.26,.5-5.95,5.47-10.58,11.51-10.66,8.23-.11,16.47-.11,24.7,0,6.21,.08,11.17,4.82,11.59,10.98,1.79,26.49,3.51,52.99,5.3,79.48,1.98,29.35,3.99,58.7,5.99,88.06,.02,.23,.15,.45,.38,1.09,1.31,0,2.77,0,4.23,0,46.03,0,92.06,0,138.09,0,9.22,0,13.71,4.51,13.71,13.77,0,22.33-.19,44.66,.11,66.99,.1,7.28-2.38,12.24-9.08,15.04H9.08Zm266.47-35.92c13.35,0,26.7,.02,40.05,0,5.72-.01,7.84-2.18,7.88-7.95,.02-2.99,.06-5.99-.01-8.98-.11-4.58-2.4-7.01-6.92-7.01-27.32-.05-54.64-.05-81.96,0-4.54,0-6.81,2.41-6.93,6.99-.07,2.87-.02,5.74-.01,8.61,.02,6.3,2.02,8.34,8.24,8.35,13.22,.01,26.45,0,39.67,0Zm-203.6-11.93c.04-6.33-5.46-11.93-11.82-12.03-6.34-.1-11.94,5.31-12.12,11.71-.18,6.44,5.52,12.25,12,12.22,6.35-.02,11.89-5.55,11.94-11.91Zm53.89-.19c-.09-6.36-5.68-11.87-12.01-11.84-6.52,.03-12.14,5.81-11.92,12.28,.21,6.37,5.91,11.82,12.19,11.66,6.38-.16,11.84-5.78,11.74-12.1Zm53.89,.08c-.04-6.35-5.59-11.89-11.93-11.92-6.51-.03-12.16,5.72-12,12.2,.16,6.37,5.8,11.84,12.11,11.74,6.37-.1,11.86-5.68,11.82-12.02Z'></path>
                           <path d='M59.78,83.92c-8.83-.08-14.51-9.44-10.52-17.36,.45-.88,1.06-1.68,1.57-2.54,3.46-5.77,3.27-9.64-.74-15.14-9.87-13.54-10.03-29.59-.42-43.08C53.94-.18,61.07-1.74,66.68,2.08c5.69,3.88,6.9,10.82,2.98,17.13-4.14,6.65-4.02,10.07,.55,16.36,9.63,13.23,9.53,29.97-.24,43.1-2.55,3.43-5.86,5.31-10.18,5.25Z'></path>
                           <path d='M167.56,83.91c-8.65-.09-14.38-9.21-10.7-17.05,.37-.78,.9-1.49,1.36-2.23,3.96-6.34,3.83-10.04-.56-16.06-9.64-13.22-9.71-29.43-.18-42.78,4.27-5.98,11.4-7.53,17-3.71,5.69,3.88,6.89,10.82,2.96,17.13-4.12,6.62-4,10.07,.56,16.35,9.62,13.24,9.51,29.99-.26,43.1-2.55,3.43-5.86,5.3-10.18,5.24Z'></path>
                        </svg>
                     </span>
                  </span>
                  <div>
                    <h4 className='font-bold leading-none pb-3'>Offset 8,211 lbs of coal emissions each year.</h4>
                    <p className='leading-tight text-sm text-gray-11'><span>Your install will offset the CO<sup>2</sup> emissions from 8,211 lbs of coal being burnt each year.</span></p>
                  </div>
                </div>
             </div>
          </div>
          <div className='hidden'>
             <div className='bg-primary-200 rounded-2xl px-2 py-3'>
                <p className='font-mono uppercase text-sm mb-8'>Carbon</p>
                <span data-aos='flip-left' data-aos-offset='200' data-aos-delay='300' className='inline-flex mb-6 inline-block h-20 w-20 rounded-full bg-primary aos-init aos-animate'>
                   <span className='m-auto'>
                      <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' className='h-9' viewBox='0 0 408.7 408.77'>
                         <path d='M133.34,0c4.71,1.49,7.6,4.85,10.11,8.99,23.13,38.17,46.4,76.26,69.57,114.41,1.36,2.23,2.72,4.7,3.15,7.22,1.29,7.41-4.32,13.52-12.33,13.8-2.92,.1-5.85,.02-8.78,.02-2.9,0-5.8,0-9.45,0,1.16,1.84,1.94,3.14,2.78,4.4,15.62,23.44,31.28,46.86,46.84,70.34,1.44,2.17,2.83,4.64,3.29,7.14,1.36,7.41-4.23,13.59-12.21,13.89-2.92,.11-5.85,.03-8.78,.03-2.92,0-5.84,0-9.56,0,1.1,1.76,1.87,3.06,2.71,4.31,15.63,23.44,31.28,46.86,46.85,70.34,1.44,2.17,2.86,4.62,3.34,7.12,1.33,6.8-3.45,12.92-10.47,13.88-1.7,.23-3.45,.16-5.18,.16-76.35,0-152.7-.05-229.04,.1-7.36,.02-12.91-1.95-16.18-8.8v-6.39c1.43-2.37,2.77-4.82,4.32-7.11,15.61-23.12,31.26-46.22,46.9-69.33,.84-1.24,1.61-2.52,2.73-4.29-6.68,0-12.53,.11-18.38-.03-6.32-.16-11.28-4.28-12.26-9.96-.71-4.1,.93-7.51,3.13-10.8,15.72-23.53,31.42-47.07,47.11-70.62,.84-1.26,1.62-2.56,2.77-4.39-3.68,0-6.58,0-9.48,0-2.93,0-5.85,.08-8.78-.02-7.99-.29-13.65-6.46-12.33-13.81,.45-2.52,1.81-4.98,3.16-7.22C72.06,85.25,95.33,47.17,118.45,9.01c2.51-4.14,5.41-7.48,10.09-9.01h4.79Z'></path>
                         <path d='M278.66,0c4.72,1.5,7.58,4.89,10.09,9.02,23.15,38.17,46.43,76.27,69.6,114.42,1.36,2.24,2.69,4.71,3.13,7.24,1.29,7.36-4.38,13.53-12.41,13.73-5.8,.15-11.61,.03-18.22,.03,1.23,1.91,2.04,3.21,2.88,4.48,15.63,23.45,31.25,46.9,46.9,70.32,2.28,3.41,4.04,6.91,3.26,11.16-1.04,5.67-6,9.68-12.39,9.82-5.81,.13-11.63,.03-18.12,.03,1.15,1.82,1.92,3.14,2.79,4.39,16.26,23.34,32.59,46.64,48.75,70.05,1.75,2.54,3.29,5.61,3.69,8.6,.82,6.21-3.83,11.51-10.21,12.58-.91,.15-1.85,.19-2.78,.19-36.32,0-72.64,0-108.96,0-.66,0-1.31-.09-1.85-.14,.51-4.53,1.37-8.96,1.43-13.4,.1-7.54-2.77-14.21-6.95-20.42-9.94-14.77-19.78-29.61-29.78-44.61,1.96-1.92,3.96-3.64,5.69-5.61,10.32-11.76,12.23-28.47,3.9-41.83-9.49-15.22-19.73-29.97-29.65-44.92-.73-1.1-1.44-2.22-2.3-3.56,.77-.73,1.49-1.47,2.26-2.14,12.53-10.89,16.24-28.92,8.3-43.49-5.4-9.9-11.55-19.4-17.54-28.97-1.4-2.24-1.39-3.79,0-6.07,14.64-23.91,29.19-47.87,43.62-71.9,2.49-4.15,5.37-7.53,10.08-9.02h4.79Z'></path>
                         <path d='M96.84,360.08h71.35c.09,1.08,.26,2.1,.26,3.12,.02,10.9,.05,21.81,0,32.71-.03,7.72-4.95,12.74-12.7,12.78-15.42,.08-30.85,.08-46.27,0-7.95-.04-12.81-5.05-12.84-13.05-.03-10.64-.02-21.28,0-31.91,0-1.16,.13-2.33,.2-3.66Z'></path>
                         <path d='M240.39,360.05h71.78c0,5.7,0,11.24,0,16.78,0,6.52,.05,13.03-.02,19.55-.08,7.02-4.95,12.22-11.96,12.28-15.96,.15-31.91,.13-47.87,0-6.35-.05-11.72-4.74-11.85-10.69-.27-12.48-.08-24.96-.08-37.92Z'></path>
                      </svg>
                   </span>
                </span>
                <h4 className='text-xl font-bold leading-none sm:h-24 pb-6'>Offset 10 acres of carbon sequestered each year.</h4>
                <p className='leading-tight text-sm text-gray-11'><span>Your install will offset the same amount of carbon sequestered by 10 acres of US Forests yearly.</span></p>
             </div>
          </div>
          <div className='hidden'>
             <div className='bg-primary-200 rounded-2xl px-2 py-3'>
                <p className='font-mono uppercase text-sm mb-8'>Gas</p>
                <span data-aos='flip-left' data-aos-offset='200' data-aos-delay='600' className='inline-flex mb-6 inline-block h-20 w-20 rounded-full bg-primary aos-init aos-animate'>
                   <span className='m-auto'>
                      <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' className='h-9' viewBox='0 0 299.27 268.8'>
                         <path d='M0,130.56c6.02-19.68,17.14-36.69,28.12-53.77,.42-.65,.87-1.29,1.33-1.92,.1-.14,.32-.2,.69-.42,.96,1.4,1.95,2.81,2.88,4.24,8.79,13.44,17.12,27.15,23.46,41.96,3.27,7.64,4.71,15.37,1.69,23.5-4.82,12.96-17.16,20.73-31.49,19.68-12.04-.88-23.37-11.22-26.07-23.76-.14-.65-.4-1.28-.6-1.92v-7.6Z'></path>
                         <path d='M121.59,93.68C89.58,64.45,54.14,40.56,14.92,21.69,21.85,14.38,28.51,7.3,35.29,.33c.46-.47,2.02-.41,2.74-.01,13.74,7.49,27.69,14.63,41.08,22.71,21.89,13.21,42.09,28.79,61.09,45.9,1.45,1.3,2.57,1.58,4.33,.71,17.57-8.65,37.46-4.17,50.09,9.12,18.72,19.7,37.6,39.26,55.98,59.27,12.23,13.32,16.98,29.42,14.84,47.44-.33,2.79-.76,5.59-1.51,8.29-.49,1.77-.1,2.86,1.09,4.13,11.1,11.75,22.15,23.56,33.21,35.35,.32,.34,.61,.72,1.03,1.23-7.08,7.46-14.1,14.87-21.24,22.41-10.64-11.25-21.13-22.35-32.27-34.12-.69,1.05-1.16,2.05-1.88,2.81-7.25,7.62-14.33,15.42-21.86,22.76-13.86,13.52-30.75,20.47-50.02,20.46-21.43,0-39.55-8.39-54.03-24.19-14.24-15.54-21.15-34.14-21.18-55.06-.03-20.16,6.45-38.18,19.76-53.55,.69-.8,1.35-1.61,2.16-2.57-2.42-2.56-4.76-5.02-7.08-7.49q-3.49-3.72-1.35-8.23c3.77-7.98,7.52-15.97,11.31-24.03Zm82,129.25c-21.18-22.38-42.23-44.61-63.43-67.01-1.21,1.4-2.28,2.52-3.21,3.74-15.25,20.14-12.82,48.43,5.63,65.7,17.27,16.18,45.06,15.18,61.01-2.43Z'></path>
                      </svg>
                   </span>
                </span>
                <h4 className='text-xl font-bold leading-none sm:h-24 pb-6'>Offset 18,490 miles of gas emissions each year.</h4>
                <p className='leading-tight text-sm text-gray-11'><span>Your install will offset the same amount of emissions from driving a passenger vehicle 18,490 miles each year.</span></p>
             </div>
         </div>
      </div>

        <h3 class='text-xl font-bold text-left sm:max-w-lg m-auto sm:text-center'>We're excited to have your back through the final stages of <span className='text-primary'>making your home shine</span> with solar.</h3>

        <div className='text-center'>
          <ViewSign project={project} />
        </div>
      </div>
    </div>
  )
}

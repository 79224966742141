import { HiDocumentText } from 'react-icons/hi2'

import Proposals from 'views/proposals'
import ProposalId from 'views/proposals/[id]'

import Installs from 'views/installs'
import Design from 'views/design/[id]'

export const sections = [
  // { label: 'Dashboard', path: '/', icon: <HiNewspaper className='h-full w-full' /> },
  {
    title: 'Tools',
    paths: [
      {
        label: 'Proposals', path: '/proposal',
        icon: <HiDocumentText className='h-full w-full' />,
        element: <Proposals />,
        children: [{
          path: '/:id',
          element: <ProposalId />
        },{
          path: '/:id/:section',
          element: <ProposalId />
        }]
      },
      {
        label: 'Installs', path: '/installs',
        icon: <HiDocumentText className='h-full w-full' />,
        element: <Installs />
      },
      {
        label: 'Design', path: '/design/:id',
        icon: <HiDocumentText className='h-full w-full' />,
        element: <Design />
      },
    ]
  }
]

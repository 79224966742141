import { useEffect, useState, useRef } from 'react'
import Thumbnails from './thumbnails'
import sections from '../sections'
import { motion } from 'framer-motion'
import { ArrowLeftIcon, ArrowRightIcon, ArrowLongDownIcon, XMarkIcon } from '@heroicons/react/24/outline'

export default function Index({ bgClass, currentIndex, setCurrentIndex }) {
  const ref = useRef(null)
  const [show, setShow] = useState(false)

  const start = currentIndex === 0
  const end = currentIndex === (sections.length - 1)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 1200)
  }, [])

  useEffect(() => {
    function handleClickOutside(event) {
      if(ref.current && !ref.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <>
      <Thumbnails bgClass={bgClass} setCurrentIndex={setCurrentIndex} />
      <div className='fixed right-6 bottom-6 navigation-buttons'>
        <motion.div
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 10, opacity: 0 }}
          transition={{ x: { type: 'spring', stiffness: 200 }, delay: .8, duration: .4 }}
          className='z-20 flex gap-2'>
          <button
            onClick={() => {
              setCurrentIndex(currentIndex-1)
            }}
            disabled={start}
            className={'z-10 group flex h-10 w-10 transition duration-700 rounded-full hover:brightness:110 ' + (start ? 'bg-gray-150 text-gray-500' : (bgClass ? 'ring-2 ring-inset ring-black' : 'bg-primary'))}>
            <ArrowLeftIcon className={'transition-[transform] duration-200 h-4.5 w-4.5 m-auto transform ' + (!start ? 'group-hover:-translate-x-1' : '')} strokeWidth={2.5} />
          </button>
          <button
            onClick={() => {
              setCurrentIndex(currentIndex+1)
            }}
            disabled={end}
            className={'group flex h-10 w-10 transition duration-700 rounded-full ' + (end ? 'bg-gray-150 text-gray-500' : (bgClass ? 'ring-2 ring-inset ring-black' : 'bg-primary'))}>
            <ArrowRightIcon className={'transition-[transform] duration-200 h-4.5 w-4.5 m-auto transform ' + (!end ? 'group-hover:translate-x-1' : '')} strokeWidth={2.5} />
          </button>
        </motion.div>
      </div>
    </>
  )
}

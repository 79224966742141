import { useRef, useState, useEffect } from 'react'

import { PlusIcon, MinusIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { getFunctions, httpsCallable } from 'firebase/functions'

import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'

export default function Index({ contact }) {
  const functions = getFunctions()

  const mapRef = useRef(null)
  const currentTiles = useRef(null)

  const [map, setMap] = useState(false)
  const [type, setType] = useState(types[4])

  useEffect(() => {
    if(mapRef.current && !map) {
      const center = [39.0997, -94.5786]

      console.log('init map')
      const initMap = L.map('map', {
        center: center,
        maxZoom: 24,
        zoom: 8,
        scrollWheelZoom: false,
        zoomControl: false
      })

      var drawnItems = new L.FeatureGroup()
      initMap.addLayer(drawnItems)
      var drawControl = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems
        },
        position: 'bottomleft'
      })
      initMap.addControl(drawControl)

      setMap(initMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, map])

  useEffect(() => {
    if(contact && map) {
      map.setView(new L.LatLng(contact.lat, contact.lng), 20)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, map])

  console.log('contact', contact)

  useEffect(() => {
    if(map && type) {
      let newTiles = false
      if(type.name === 'roadmap-stadia-dark') {
        newTiles = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
         maxZoom: 24,
         maxNativeZoom: 20,
         attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a>'
        }).addTo(map)
      } else if(type.name === 'roadmap-carto') {
        newTiles = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        	attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>',
        	subdomains: 'abcd',
        	maxZoom: 24,
          maxNativeZoom: 20,
        }).addTo(map)
      } else if(type.name === 'roadmap-mapbox-dark') {
        newTiles = L.tileLayer('https://api.mapbox.com/styles/v1/ozpoo/clakgv2x8001016rw2lvudmv0/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
         attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
         tileSize: 512,
         zoomOffset: -1,
         maxZoom: 24,
         maxNativeZoom: 20,
        }).addTo(map)
      } else if(type.name === 'roadmap-mapbox') {
        newTiles = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
         attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
         tileSize: 512,
         zoomOffset: -1,
         maxZoom: 24,
         maxNativeZoom: 20,
        }).addTo(map)
      } else if(type.name === 'roadmap-google') {
        newTiles = L.tileLayer('http://{s}.google.com/vt/lyrsf=m&x={x}&y={y}&z={z}',{
          maxZoom: 24,
          maxNativeZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3'],
          attribution: '&copy; <a href="http://bing.com/maps">Google Maps</a>'
        }).addTo(map)
      } else if(type.name === 'satellite-mapbox') {
        newTiles = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
         attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
         tileSize: 512,
         zoomOffset: -1,
         maxZoom: 24,
         maxNativeZoom: 18,
        }).addTo(map)
      } else if(type.name === 'satellite-usgs') {
        newTiles = L.tileLayer('https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}', {
          maxZoom: 24,
          maxNativeZoom: 20,
        	attribution: '&copy; <a href="https://usgs.gov/">U.S. Geological Survey</a>'
        }).addTo(map)
      } else if(type.name === 'satellite-google') {
        newTiles = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
          maxZoom: 24,
          maxNativeZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3'],
          attribution: '&copy; <a href="http://bing.com/maps">Google Maps</a>'
        }).addTo(map)
      } else if(type.name === 'satellite-bing') {
        async function getBing() {
          const url = 'https://dev.virtualearth.net/REST/V1/Imagery/Metadata/Aerial?output=json&include=ImageryProviders&key='+process.env.REACT_APP_BING_API
          const tiles = await fetch(url)
          const tilesJSON = await tiles.json()

          var BingLayer = L.TileLayer.extend({
            getTileUrl: function (tilePoint) {
                // this._adjustTilePoint(tilePoint);
                return L.Util.template(this._url, {
                    s: this._getSubdomain(tilePoint),
                    q: this._quadKey(tilePoint.x, tilePoint.y, this._getZoomForUrl())
                });
            },
            _quadKey: function (x, y, z) {
                var quadKey = [];
                for (var i = z; i > 0; i--) {
                    var digit = '0';
                    var mask = 1 << (i - 1);
                    if ((x & mask) != 0) {
                        digit++;
                    }
                    if ((y & mask) != 0) {
                        digit++;
                        digit++;
                    }
                    quadKey.push(digit);
                }
                return quadKey.join('');
            }
          })

          const tilesUrl = tilesJSON.resourceSets[0].resources[0].imageUrl.replace('{subdomain}', '{s}').replace('{quadkey}', '{q}')

          newTiles = new BingLayer(tilesUrl, {
            maxZoom: 24,
            maxNativeZoom: 20,
            subdomains: tilesJSON.resourceSets[0].resources[0].imageUrlSubdomains,
            attribution: '&copy; <a href="http://bing.com/maps">Bing Maps</a>'
          }).addTo(map)
        }
        getBing()
      } else if(type.name === 'satellite-arcgis') {
        newTiles = L.tileLayer('http://{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          maxZoom: 24,
          maxNativeZoom: 20,
          subdomains: ['server', 'services'],
          attribution: '&copy; <a href="http://www.arcgis.com/">ArcGIS esri</a>'
        }).addTo(map)
      }

      if(currentTiles.current) map.removeLayer(currentTiles.current)
      currentTiles.current = newTiles
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, type])

  return (
    <div className='h-full w-full relative'>
      <div className={'transition duration-200 pointer-events-none absolute z-20 inset-0 bg-gray-200/40 backdrop-blur flex ' + (contact ? 'opacity-0' : 'opacity-100')}>
        <div className='m-auto text-xl'>
          Loading...
        </div>
      </div>
      {contact &&
        <div className='fade-in shadow-lg text-sm w-full max-w-xs z-10 m-3 absolute bottom-0 left-11 bg-secondary rounded-2xl p-3'>
          <p>{contact.firstname} {contact.lastname}</p>
          <p className='truncate'>{contact.formatted_address}</p>
          <p>{contact.phone}</p>
        </div>
      }
      <ul className='m-3 mb-6 absolute bottom-0 right-0 flex flex-col gap-1 z-10'>
        <li>
          <button
            className='shadow-lg flex text-sm bg-white rounded-full h-9 w-9 hover:opacity-80 flex items-center transition duration-200'
            onClick={() => map.setZoom(map.getZoom() + 1)}>
            <PlusIcon className='h-4 w-4 m-auto' strokeWidth={2.5} />
          </button>
        </li>
        <li>
          <button
            className='shadow-lg flex text-sm bg-white rounded-full h-9 w-9 hover:opacity-80 flex items-center transition duration-200'
            onClick={() => map.setZoom(map.getZoom() - 1)}>
            <MinusIcon className='h-4 w-4 m-auto' strokeWidth={2.5} />
          </button>
        </li>
      </ul>
      <div className='absolute top-0 left-0 z-10 m-3 flex gap-2'>
        {types && <Types type={type} setType={setType} types={types} />}
      </div>
      <div ref={mapRef} id='map' className='relative h-full w-full z-0' />
    </div>
  )
}

function Types({ type, setType, types }) {
  const ref = useRef(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if(ref.current && !ref.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div ref={ref} className='relative'>
      <button
        onClick={() => {
          setShow(!show)
        }}
        className='flex items-center gap-3 shadow-lg px-4 py-1.5 text-sm rounded-full bg-white hover:bg-gray-100 transition duration-200'>
        {type.title}
        <ChevronDownIcon className='h-3.5 w-3.5' strokeWidth={2.5} />
      </button>
      {show &&
        <ul className='divide-y fade-in w-56 shadow-lg overflow-hidden absolute top-full left-0 mt-3 bg-white rounded-2xl'>
          {types.map(t =>
            <li key={t.name}>
              <button
                className={'text-sm px-4 py-2.5 w-full text-left transition duration-200 ' + (type.title === t.title ? 'bg-primary-200' : 'hover:bg-gray-100')}
                onClick={() => {
                  setShow(false)
                  setType(t)
                }}>
                {t.title}
              </button>
            </li>
          )}
        </ul>
      }
    </div>
  )
}

const types = [
  // {
  //   name: 'roadmap-stadia-dark',
  //   url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
  //   maxZoom: 20,
  //   attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a>'
  // },
  {
    title: 'Roadmap Light',
    name: 'roadmap-carto',
    url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>',
    subdomains: 'abcd',
    maxZoom: 20
  },
  {
    title: 'Roadmap Dark',
    name: 'roadmap-mapbox-dark',
    url: 'https://api.mapbox.com/styles/v1/ozpoo/clakgv2x8001016rw2lvudmv0/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY,
    attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
    tileSize: 512,
    zoomOffset: -1
  },
  // {
  //   name: 'roadmap-mapbox',
  //   url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY,
  //   attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
  //   tileSize: 512,
  //   zoomOffset: -1
  // },
  // {
  //   title: 'Roadmap Google',
  //   name: 'roadmap-google',
  //   url: 'http://{s}.google.com/vt/lyrsf=m&x={x}&y={y}&z={z}',
  //   maxZoom: 20,
  //   subdomains:['mt0','mt1','mt2','mt3'],
  //   attribution: '&copy; <a href="http://bing.com/maps">Google Maps</a>'
  // },
  {
    title: 'Satellite Mapbox',
    name: 'satellite-mapbox',
    url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY,
    attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
    tileSize: 512,
    zoomOffset: -1
  },
  // {
  //   name: 'satellite-usgs',
  //   url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}',
  //   maxZoom: 20,
  //   attribution: '&copy; <a href="https://usgs.gov/">U.S. Geological Survey</a>'
  // },
  {
    title: 'Satellite Google',
    name: 'satellite-google',
    url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    maxZoom: 20,
    subdomains:['mt0','mt1','mt2','mt3'],
    attribution: '&copy; <a href="http://bing.com/maps">Google Maps</a>'
  },
  {
    title: 'Satellite Bing',
    name: 'satellite-bing',
    get: async (map) => {
      // const url = 'https://dev.virtualearth.net/REST/V1/Imagery/Metadata/Aerial?output=json&include=ImageryProviders&key='+process.env.REACT_APP_BING_API
      // const tiles = await fetch(url)
      // const tilesJSON = await tiles.json()
      //
      // var BingLayer = L.TileLayer.extend({
      //   getTileUrl: function (tilePoint) {
      //       // this._adjustTilePoint(tilePoint);
      //       return L.Util.template(this._url, {
      //           s: this._getSubdomain(tilePoint),
      //           q: this._quadKey(tilePoint.x, tilePoint.y, this._getZoomForUrl())
      //       });
      //   },
      //   _quadKey: function (x, y, z) {
      //       var quadKey = [];
      //       for (var i = z; i > 0; i--) {
      //           var digit = '0';
      //           var mask = 1 << (i - 1);
      //           if ((x & mask) != 0) {
      //               digit++;
      //           }
      //           if ((y & mask) != 0) {
      //               digit++;
      //               digit++;
      //           }
      //           quadKey.push(digit);
      //       }
      //       return quadKey.join('');
      //   }
      // })
      //
      // const tilesUrl = tilesJSON.resourceSets[0].resources[0].imageUrl.replace('{subdomain}', '{s}').replace('{quadkey}', '{q}')
      //
      // newTiles = new BingLayer(tilesUrl, {
      //   maxZoom: 20,
      //   subdomains: tilesJSON.resourceSets[0].resources[0].imageUrlSubdomains,
      //   attribution: '&copy; <a href="http://bing.com/maps">Bing Maps</a>'
      // }).addTo(map)
    }
  },
  // {
  //   name: 'satellite-arcgis',
  //   url: 'http://{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  //   maxZoom: 20,
  //   subdomains: ['server', 'services'],
  //   attribution: '&copy; <a href="http://www.arcgis.com/">ArcGIS esri</a>'
  // }
]

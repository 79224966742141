import Welcome from './welcome'
import Proposal from './proposal'
import HowSolarWorks from './how-solar-works'
import WhySolar from './why-solar'
import WhyAstrawatt from './why-astrawatt'
import ThankYou from './thank-you'
import WhatsNext from './whats-next'
import WhatWeDo from './what-we-do'

export {
  Welcome,
  Proposal,
  HowSolarWorks,
  WhySolar,
  WhyAstrawatt,
  ThankYou,
  WhatsNext,
  WhatWeDo
}

const sections = [
  {
    label: 'Welcome',
    slug: 'welcome',
    element: ({ project, proposals, production, materials, financing, drawings, active, index, section, contact, salesRep, neighbors }) => { return <Welcome project={project} proposals={proposals} production={production} materials={materials} financing={financing} drawings={drawings} index={index} active={active} section={section} contact={contact} salesRep={salesRep} neighbors={neighbors} /> }
  },
  {
    label: 'Your Solar Proposal',
    slug: 'proposal',
    element: ({ project, proposals, production, materials, financing, drawings, active, index, section, contact, salesRep, neighbors }) => { return <Proposal project={project} proposals={proposals} production={production} materials={materials} financing={financing} drawings={drawings} index={index} active={active} section={section} contact={contact} salesRep={salesRep} neighbors={neighbors} /> }
  },
  {
    label: 'How Solar Works',
    slug: 'how-solar-works',
    element: ({ project, proposals, production, materials, financing, drawings, active, index, section, contact, salesRep, neighbors }) => { return <HowSolarWorks project={project} proposals={proposals} production={production} materials={materials} financing={financing} drawings={drawings} index={index} active={active} section={section} contact={contact} salesRep={salesRep} neighbors={neighbors} /> }
  },
  {
    label: 'Why Solar',
    slug: 'why-solar',
    element: ({ project, proposals, production, materials, financing, drawings, active, index, section, contact, salesRep, neighbors }) => { return <WhySolar project={project} proposals={proposals} production={production} materials={materials} financing={financing} drawings={drawings} index={index} active={active} section={section} contact={contact} salesRep={salesRep} neighbors={neighbors} /> }
  },
  {
    label: 'Why Astrawatt',
    slug: 'why-astrawatt',
    element: ({ project, proposals, production, materials, financing, drawings, active, index, section, contact, salesRep, neighbors }) => { return <WhyAstrawatt project={project} proposals={proposals} production={production} materials={materials} financing={financing} drawings={drawings} index={index} active={active} section={section} contact={contact} salesRep={salesRep} neighbors={neighbors} /> }
  },
  {
    label: 'What We Do',
    slug: 'what-we-do',
    element: ({ project, proposals, production, materials, financing, drawings, active, index, section, contact, salesRep, neighbors }) => { return <WhatWeDo project={project} proposals={proposals} production={production} materials={materials} financing={financing} drawings={drawings} index={index} active={active} section={section} contact={contact} salesRep={salesRep} neighbors={neighbors} /> }
  },
  {
    label: 'Thank You',
    slug: 'thank-you',
    element: ({ project, proposals, production, materials, financing, drawings, active, index, section, contact, salesRep, neighbors }) => { return <ThankYou project={project} proposals={proposals} production={production} materials={materials} financing={financing} drawings={drawings} index={index} active={active} section={section} contact={contact} salesRep={salesRep} neighbors={neighbors} /> }
  },
  {
    label: 'What\'s Next',
    slug: 'whats-next',
    element: ({ project, proposals, production, materials, financing, drawings, active, index, section, contact, salesRep, neighbors }) => { return <WhatsNext project={project} proposals={proposals} production={production} materials={materials} financing={financing} drawings={drawings} index={index} active={active} section={section} contact={contact} salesRep={salesRep} neighbors={neighbors} /> }
  }
]

export default sections

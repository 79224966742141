import { motion } from 'framer-motion'
import { pageVariants } from 'framer-animations'

export default function Index({ children }) {
  return (
    <motion.div
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}>
      {children}
    </motion.div>
  )
}

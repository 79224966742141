import { useEffect, useRef, useState, useContext } from 'react'

import { PlusIcon, CheckIcon } from '@heroicons/react/24/solid'

import { Context } from 'store/index'

export default function Index() {
  const [state] = useContext(Context)
  return (
    <div className='flex h-screen w-screen p-3 py-10vh'>
      <div className='flex flex-col justify-center sm:flex-row w-full gap-12 w-full max-w-sm sm:max-w-3xl m-auto'>
        {!state.settings.hubspotOwnerID &&
          <div className='fade-in flex-1 text-center shrink-0'>
            <Hubspot />
          </div>
        }
      </div>
    </div>
  )
}

function Hubspot() {
  const [state] = useContext(Context)

  const [owner, setOwner] = useState(false)
  const [status, setStatus] = useState('loading')

  useEffect(() => {
    setStatus('loading')
  }, [])

  return (
    <div className='space-y-6'>
      <p className='flex items-center gap-2 justify-center'>
        <svg xmlns='http://www.w3.org/2000/svg' fill='#ff5c35' className='h-10 w-10' viewBox='0 0 13.107 13.099' preserveAspectRatio='xMidYMid'><path d='M12.027 6.222a3.33 3.33 0 0 0-1.209-1.201c-.382-.222-.777-.363-1.223-.424V3a1.17 1.17 0 0 0 .722-1.097 1.2 1.2 0 0 0-1.2-1.206 1.21 1.21 0 0 0-1.21 1.206c0 .49.26.908.707 1.097v1.588a3.49 3.49 0 0 0-1.064.334L3.275 1.685c.03-.113.056-.23.056-.353 0-.738-.598-1.336-1.336-1.336S.66.594.66 1.332s.598 1.336 1.336 1.336c.252 0 .485-.074.686-.195l.28.212L6.797 5.45c-.203.186-.392.398-.543.636-.306.485-.493 1.018-.493 1.6v.12a3.35 3.35 0 0 0 .21 1.156c.116.316.286.604.497.864l-1.274 1.277c-.377-.14-.8-.047-1.085.238-.194.193-.303.456-.302.73s.108.535.303.73.456.303.73.303.537-.108.73-.303.303-.456.302-.73a1.03 1.03 0 0 0-.048-.31l1.316-1.316c.18.125.375.23.585.32a3.42 3.42 0 0 0 1.369.288h.09c.552 0 1.073-.13 1.562-.395a3.23 3.23 0 0 0 1.224-1.153c.307-.49.475-1.033.475-1.63v-.03c0-.587-.136-1.128-.42-1.624zM10.42 8.984c-.357.397-.768.642-1.232.642H9.1c-.265 0-.525-.073-.778-.207a1.8 1.8 0 0 1-.682-.621c-.184-.26-.284-.544-.284-.845v-.09c0-.296.057-.577.2-.842.153-.3.36-.515.635-.694s.558-.265.88-.265h.03c.29 0 .567.057.827.19a1.75 1.75 0 0 1 .65.591 1.88 1.88 0 0 1 .291.83l.007.187c0 .407-.156.784-.467 1.126z' /></svg>
        Hubspot
      </p>
      <p className='font-mono text-sm uppercase tracking-tighter'>Let's connect to your<br />HubSpot account</p>
      {status === 'loading' &&
        <div data-aos='fade-in'>
          Loading...
        </div>
      }
      {status === 'start' &&
        <div data-aos='fade-in'>
          <button
            onClick={() => {}}
            className='font-bold text-sm inline-block rounded-full px-6 py-2 bg-black-800 hover:bg-black-775 active:bg-black-750'>
            Connect to HubSpot
          </button>
        </div>
      }
      {status === 'searching' &&
        <div data-aos='fade-in' className='pb-16'>Hang tight, we're looking for your account!</div>
      }
      {status === 'select' &&
        <div data-aos='fade-in' className='text-sm space-y-6'>
          <p>Sorry!</p>
          <p>We couldn't find an associated Hubspot owner account for {state.user.email}.</p>
          <p>Can you find your account below?</p>
          {state.contactOwners &&
            <ul className='divide-y w-full'>
              {state.contactOwners.map(owner =>
                <li key={owner.value} className='text-left flex items-center py-2'>
                  <span className='flex-1 truncate'>{owner.label}</span>
                  <span className='flex-1 truncate text-black-400'>{owner.email}</span>
                  <button
                    onClick={() => {
                      setOwner(owner)
                    }}
                    className='bg-black-825 hover:bg-black-800 rounded-full px-6 py-1 text-xs font-bold'>
                    Select
                  </button>
                </li>
              )}
            </ul>
          }
          {/*<div className='hidden flex items-center gap-2'>
            <input
              className='flex-1'
              type='text'
              onChange={(e) => setHubspot(e.target.value)} />
            <button
              disabled={!hubspot}
              onClick={updateHubspot}
              className='bg-black-825 hover:bg-black-800 rounded-full px-6 py-2 text-primary font-bold'>
              Set
            </button>
          </div>*/}
        </div>
      }
      {status === 'connected' &&
        <div data-aos='fade-in' className='max-w-sm m-auto text-left rounded-full p-6 bg-black-850 flex items-center gap-6'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-10 h-10 text-primary'>
            <path fill-rule='evenodd' d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z' clip-rule='evenodd' />
          </svg>
          <div>
            <p className='pb-4 truncate'>Hubspot account connected</p>
            <p className='text-black-400 truncate'>{owner.email}</p>
            <p className='text-black-400 truncate'>{owner.value}</p>
          </div>
        </div>
      }
      <p className='text-xs text-black-400 leading-tight'>Connect to your Hubspot account so we can make sure<br />your data is synced up across platforms.</p>
    </div>
  )
}

function Twilio({ area, owners }) {
  const [state] = useContext(Context)

  const [serviceAreas, setServiceAreas] = useState(false)
  const [phoneNumbers, setPhoneNumbers] = useState(false)

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getServiceAreas() {
      const response = await fetch('https://us-central1-risingsun-solar.cloudfunctions.net/hubspotGetProperty', {
        method: 'POST',
        body: JSON.stringify({ objectType: 'contacts', propertyName: 'service_area' })
      })
      const data = await response.json()
      if(mounted.current) {
        setServiceAreas([
          {
            value: 'default',
            label: 'Default',
          },
          ...data.body.options.sort((a, b) => a.label.localeCompare(b.label))
        ])
      }
    }
    async function getTwilioPhoneNumbers() {
      const response = await fetch('https://us-central1-risingsun-solar.cloudfunctions.net/twilioIncomingPhoneNumbersGetList', {
        method: 'POST',
        body: JSON.stringify({ })
      })
      const data = await response.json()
      if(mounted.current) setPhoneNumbers(data.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName)))
    }
    getServiceAreas()
    getTwilioPhoneNumbers()
  }, [])

  return (
    <div className='space-y-6'>
      <p className='flex items-center gap-2 justify-center'>
        <svg xmlns='http://www.w3.org/2000/svg' fill='#008CFF' className='h-10 w-10' viewBox='0 0 30 30'><circle cx='11.3' cy='11.3' r='3.1'/><circle cx='11.3' cy='18.7' r='3.1'/><path d='M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15,15-6.7,15-15S23.3,0,15,0Zm0,26c-6.1,0-11-4.9-11-11S8.9,4,15,4s11,4.9,11,11-4.9,11-11,11Z'/><circle cx='18.7' cy='18.7' r='3.1'/><circle cx='18.7' cy='11.3' r='3.1'/></svg>
        Twilio
      </p>
      <p className='font-mono text-sm uppercase tracking-tighter'>Let's connect your Twilio<br />fallback number</p>
      {state.settings.twilioSid &&
        <div data-aos='fade-in' className='max-w-sm m-auto text-left rounded-full p-6 bg-black-850 flex items-center gap-6'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-10 h-10 text-primary'>
            <path fill-rule='evenodd' d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z' clip-rule='evenodd' />
          </svg>
          <div>
            <p className='pb-4 truncate'>Twilio account connected</p>
            <p className='text-black-400 truncate'>{state.settings.twilioFriendlyName}</p>
            <p className='text-black-400 truncate'>{state.settings.twilioPhone}</p>
          </div>
        </div>
      }
      <div className='hidden'>
        <ul className='space-y-3'>
          {serviceAreas && serviceAreas.map(area =>
            <li key={area.label} className={'relative ' + (area.value === 'default' ? 'mb-8' : '')}>
              <SelectPhoneNumber area={area} phoneNumbers={phoneNumbers} />
            </li>
          )}
        </ul>
      </div>
      <p className='text-xs text-black-400 leading-tight'>Select the Twilio number you will use as a fallback<br />number to call and send messages.</p>
    </div>
  )
}

function SelectPhoneNumber({ area, phoneNumbers }) {
  const [state] = useContext(Context)

  const [show, setShow] = useState(false)
  const [current, setCurrent] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if(ref.current && !ref.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  useEffect(() => {
    if(area) {
      if(state.settings && state.settings.numbers) {
        const number = state.settings.numbers.find(n => n.serviceArea === area.value)
        if(number) {
          setCurrent(number)
        } else {
          setCurrent(false)
        }
      }
    } else {
      setCurrent(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area])

  return (
    <div ref={ref} className='relative z-0'>
      <button
        onClick={() => setShow(!show)}
        className='gap-3 flex items-center text-left w-full relative z-0 bg-black-825 rounded-lg p-2 hover:bg-black-800 active:bg-black-775'>
        {current &&
          <span className='h-10 w-10 flex bg-black-800 rounded-full'>
            <CheckIcon className='h-5 w-5 m-auto text-primary' />
          </span>
        }
        <span className='block flex-1'>
          <span className='block'>{area.label}</span>
          <span className='text-black-400 block'>{current ? current : 'Not set'}</span>
        </span>
        <PlusIcon className='h-4.5 w-4.5 text-black-400' />
      </button>
      {show &&
        <div className='z-10 absolute w-44 top-full left-0 mt-2 bg-black-800 rounded-xl py-6'>
          <ul>
            {phoneNumbers && phoneNumbers.map(number =>
              <li key={number.sid}>
                <button
                  className='flex gap-3 items-center text-sm w-full px-3 py-3 text-left hover:bg-black-775'
                  onClick={() => {
                    setShow(false)
                  }}>
                  <span className='flex-1'>{number.friendlyName}</span>
                  <span className='flex-1'>{number.phoneNumber}</span>
                </button>
              </li>
            )}
          </ul>
        </div>
      }
    </div>
  )
}

import { useRef, useEffect, useContext } from 'react'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore, doc, onSnapshot, setDoc } from 'firebase/firestore'

import { Context } from 'store/index'

import { AnimatePresence } from 'framer-motion'

import NotAuthenticated from 'components/routes/not-authenticated'
import Authenticated from 'components/routes/authenticated'
import Loading from 'components/routes/loading'
import EmailSetup from 'components/routes/email-setup'
import AccountSetup from 'components/routes/account-setup'

import { passwordStale } from 'lib/utils'

export default function Index() {
  const [state, dispatch] = useContext(Context)

  const auth = getAuth()
  const db = getFirestore()

  const mounted = useRef(null)
  const profileUnsub = useRef(null)
  const settingsUnsub = useRef(null)

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  }, [])

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      async function getProfile() {
        console.log('get profile')
        if(profileUnsub.current) profileUnsub.current()
        profileUnsub.current = onSnapshot(doc(db, 'users', user.uid), { includeMetadataChanges: true }, (doc) => {
          console.log(doc)
          if(mounted.current === true && doc.exists()) {
            dispatch({ type: 'profile-set', payload: doc.data() })
            console.log('profile')
          } else {
            console.log('no profile')
            dispatch({ type: 'profile-set', payload: null })
            createProfile(user)
          }
        })
      }

      async function getSettings() {
        console.log('get settings')
        if(settingsUnsub.current) settingsUnsub.current()
        settingsUnsub.current = onSnapshot(doc(db, 'lightsale-users-settings', user.uid), { includeMetadataChanges: true }, settings => {
          if(mounted.current === true && settings.exists()) {
            dispatch({ type: 'settings-set', payload: settings.data() })
          } else {
            dispatch({ type: 'settings-set', payload: null })
            createSettings(user)
            // createSettings(user.uid)
          }
        })
      }

      if(user) {
        // if(loggedIn !== true) setLoggedIn(true)
        dispatch({ type: 'user-set', payload: user })
        getProfile()
        getSettings()
      } else {
        // if(loggedIn !== false) setLoggedIn(false)
        dispatch({ type: 'user-set', payload: false })
        dispatch({ type: 'profile-set', payload: false })
        dispatch({ type: 'settings-set', payload: false })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   if(loggedIn === false) {
  //     if(!['/','/forgot-password'].includes(location.pathname)) {
  //       navigate('/')
  //     }
  //   } else if(loggedIn === true) {
  //     if(['/forgot-password'].includes(location.pathname)) {
  //       navigate('/')
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loggedIn])

  function createSettings(user) {
    setDoc(doc(db, 'lightsale-users-settings', user.uid), {
      uid: user.uid,
      email: user.email,
      createdAt: new Date(),
      updatedAt: new Date()
    }, { merge: true })
  }

  function createProfile(user) {
    setDoc(doc(db, 'users', user.uid), {
      uid: user.uid,
      email: user.email,
      createdAt: new Date(),
      updatedAt: new Date()
    }, { merge: true })
  }

  function passwordCheck() {
    if(state.user && (!state.user.emailVerified || (!state.user.reloadUserInfo || passwordStale(state.user.reloadUserInfo.passwordUpdatedAt)))) {
      return true
    } else {
      return false
    }
  }

  function accountCheck() {
    if(state.settings && !state.settings.hubspotOwnerID){
      return true
    } else {
      return false
    }
  }

  return (
    <AnimatePresence exitBeforeEnter mode='wait'>
      {/* !loggedIn ? <NotAuthenticated key='not-authenticated' /> : <Authenticated key='authenticated' /> */}
      {!state.user && <NotAuthenticated key='not-authenticated' />}
      {state.user && !state.profile && !state.settings && <Loading key='loading' />}
      {state.user && state.profile && passwordCheck() && <EmailSetup key='email-setup' />}
      {state.user && state.profile && state.settings && accountCheck() && <AccountSetup />}
      {state.user && state.profile && state.settings && !accountCheck() && <Authenticated key='authenticated' />}
    </AnimatePresence>
  )
}

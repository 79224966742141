import { useRef, useState, useEffect, useContext } from 'react'

import { motion } from 'framer-motion'

import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline'

import { Context } from 'store/index'
import Logo from 'components/logo/logo'

import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

export default function Index({ contact, neighbors }) {
  const [state] = useContext(Context)

  const mapRef = useRef(null)

  const [map, setMap] = useState()
  const [markers, setMarkers] = useState(false)
  const [clusters, setClusters] = useState(false)

  useEffect(() => {
    if(!mapRef.current) {
      console.log('init map')
      const initMap = L.map('map', {
        center: [contact.lat, contact.lng],
        maxZoom: 20,
        zoom: 8,
        scrollWheelZoom: false,
        zoomControl: false
      })
      L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 20
      }).addTo(initMap)
      mapRef.current = initMap
      setMap(initMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  useEffect(() => {
    async function getMarkers() {
      console.log('set markers')
      setMarkers(false)

      setMarkers(neighbors.filter(doc => {
        if(!doc.lat || doc.lat === 'undefined' || !doc.lng || doc.lng === 'undefined') return false
        return true
      }).map(doc => {
        const position = [parseFloat(doc.lat), parseFloat(doc.lng)]
        const svgIcon = L.divIcon({
          html: `
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
</svg>`,
          className: 'text-primary',
          iconSize: [32, 32],
          iconAnchor: [16, 32],
        })

        const marker = L.marker(position, {
          icon: svgIcon
        })

        // const newDiv = document.createElement('div')
        // const text = document.createElement('p')
        // text.innerHTML = '<p><b>' + doc.first_name + ' ' + doc.last_name + '</b></br>' + doc.address + '</p>'
        //
        // const button = document.createElement('button')
        // button.appendChild(document.createTextNode('View'))
        // button.onclick = () => {
        //   alert('hi')
        // }
        // button.className = 'font-bold bg-primary px-3 py-1 rounded-full'
        //
        // newDiv.appendChild(text)
        // newDiv.appendChild(button)
        //
        // marker.bindPopup(newDiv).openPopup()

        return marker
      }))
    }

    if(neighbors && neighbors.length > 0 && map) getMarkers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [neighbors, map])

  useEffect(() => {
    if(markers) {
      if(clusters) map.removeLayer(clusters)

      const newClusters = L.markerClusterGroup()
      markers.forEach(m => {
        newClusters.addLayer(m)
      })
      map.addLayer(newClusters)

      setClusters(newClusters)

      const features = new L.featureGroup(markers)
      map.fitBounds(features.getBounds())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, markers])

  return (
    <>
      <motion.div
        className='text-center'
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, delay: .4, duration: .4 }}>
        <p className='font-mono uppercase mb-10'>Your Solar Community</p>
        <h1 className='font-body font-bold text-3xl max-w-3xl mb-24'>There are currently <span className='text-primary'>{neighbors && neighbors.length}</span> satisfied Astrawatt Solar homes in your neighborhood.</h1>
      </motion.div>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, delay: .4, duration: .4 }}
        className='mb-24 relative aspect-video w-full rounded-3xl max-w-3xl overflow-hidden'>
        <ul className='hidden m-3 absolute bottom-0 left-0 flex flex-col gap-1 z-10'>
          <li>
            <button
              className='flex text-sm bg-primary text-black rounded-full h-8 w-8 hover:opacity-80 flex items-center transition duration-200'
              onClick={() => map.setZoom(map.getZoom() + 1)}>
              <PlusIcon className='h-4 w-4 m-auto' strokeWidth={2.5} />
            </button>
          </li>
          <li>
            <button
              className='flex text-sm bg-primary text-black rounded-full h-8 w-8 hover:opacity-80 flex items-center transition duration-200'
              onClick={() => map.setZoom(map.getZoom() - 1)}>
              <MinusIcon className='h-4 w-4 m-auto' strokeWidth={2.5} />
            </button>
          </li>
        </ul>
        <div className='h-full w-full relative z-0'>
          <div id='map' className='relative h-full w-full z-0' />
        </div>
      </motion.div>
    </>
  )
}

import { UserIcon, PhoneIcon, ChatBubbleOvalLeftIcon, EnvelopeIcon } from '@heroicons/react/24/solid'

import { motion } from 'framer-motion'

import useSound from 'use-sound'
import boopSfx from 'sounds/notification_simple-01.wav'

import Video from '../components/video-intro'
import { toTitleCase } from 'lib/utils'

export default function Index({ project, proposals, production, materials, financing, drawings, active, index, contact, salesRep }) {
  const [play] = useSound(boopSfx)

  return (
    <div className='px-6 py-24 flex flex-col items-center'>
      <p className='font-mono uppercase mb-8'>We're Happy To See You</p>
      <h1 className='font-bold text-4xl mb-11 text-center max-w-3xl'>Hello sunshine &mdash; welcome to your Astrawatt Solar <span className='text-primary'>custom solar design</span> proposal.</h1>
      <div className='h-56 w-56 mb-11 mx-auto relative bg-gray-200 rounded-full'>
        {salesRep.head_shot && <img loading='lazy' alt={salesRep.firstname + ' ' + salesRep.lastname + ' head shot.'} src={salesRep.head_shot} className='relative z-10 mx-auto block h-full w-full rounded-full' />}
        <div className='absolute top-0 left-0 z-0 h-full w-full rounded-full bg-gray-200 inline-flex mx-auto items-center justify-center'>
          <UserIcon className='h-5 w-5' />
        </div>
        <div className='transform -translate-y-1/2 translate-x-3 w-72 absolute top-1/2 left-full'>
          <motion.div
            initial={{ x: -20, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ x: { type: 'spring', stiffness: 200 }, delay: .4, duration: .4 }}
            className='px-3 pt-4 pb-5 relative bg-primary-200 rounded-2xl h-full w-full'>
            <div className='transform -translate-y-1/2 -translate-x-full h-0 w-0 border-y-8 border-y-transparent border-r-[12px] border-r-primary-200 absolute top-1/2 left-0'></div>
            <span className='font-bold'>Hey{!contact && <span className='fade-in'> there</span>}{contact && <span className='fade-in'> {contact.firstname && toTitleCase(contact.firstname)}</span>}</span> &mdash; I'm {salesRep.firstname} {salesRep.lastname} and I'll be helping you with your solar proposal.
          </motion.div>
        </div>
      </div>

      <div className='relative'>
        <Video />
      </div>

      <div className='text-center max-w-3xl mx-auto mb-8'>
        <div>
          <p className='truncate text-lg'><span className='font-bold'>{salesRep.firstname} {salesRep.lastname}</span><span className='text-gray-500'> &mdash; {salesRep.jobtitle}</span></p>
          <p className='font-mono leading-none tracking-tightest mt-2 mb-8'>{salesRep.phone}</p>
          <p><a className='hover:bg-gray-100 transition duration-200 bg-gray-150 font-bold p-5 py-2 pb-2.5 rounded-full' href={'mailto:' + salesRep.email}>{salesRep.email}</a></p>
        </div>
      </div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ y: { type: 'spring', stiffness: 200 }, delay: .4, duration: .4 }}
        className='px-3 pt-5 pb-6 relative bg-primary-200 rounded-2xl max-w-sm'>
        <div className='transform -translate-y-full -translate-x-1/2 h-0 w-0 border-x-8 border-x-transparent border-b-[12px] border-b-primary-200 absolute top-0 left-1/2'></div>
        <p className='font-bold mb-4'>Don't Be Shy</p>
        <p className='max-w-md mx-auto mb-4'>If you find yourself scratching your head with questions or comments I'm here to help.</p>
        <p>Reach out to me using your preferred method!</p>
      </motion.div>
    </div>
  )
}

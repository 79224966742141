import { useEffect, useState, useRef, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'

import { getApp } from 'firebase/app'
import { getFirestore, getDoc, doc, query, collection, where, getDocs } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { Context } from 'store/index'
import { toTitleCase } from 'lib/utils'

import LayoutTransition from 'components/transitions/layout'
import ScaleOutTransition from 'components/transitions/scale-out'
import Nav from './components/nav'

import { AnimatePresence } from 'framer-motion'

import { HiOutlineXMark } from 'react-icons/hi2'

import sections from './sections'

import Joyride from 'react-joyride'

const steps = [
  {
    disableBeacon: true,
    target: '.navigation-buttons',
    title: 'Navigation',
    content: 'Use these buttons to navigate through your solar proposal.',
  },{
    disableBeacon: true,
    target: '.menu-button',
    title: 'Menu',
    content: 'Use this buttons to view a menu of your proposal sections.',
  },{
    disableBeacon: true,
    target: '.intro-video',
    title: 'Proposal Explaination',
    content: 'Click this video to watch a personalized walk through of your proposal.',
  }
]

export default function Index() {
  const params = useParams()
  const functions = getFunctions()
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [run, setRun] = useState(false)

  const [state] = useContext(Context)

  const mounted = useRef(null)

  const [project, setProject] = useState(false)
  const [proposals, setProposals] = useState(false)

  const [contact, setContact] = useState(false)
  const [salesRep, setSalesRep] = useState(false)
  const [deal, setDeal] = useState(true)

  const [neighbors, setNeighbors] = useState(false)

  const [currentIndex, setCurrentIndex] = useState(false)

  const [firstLoad, setFirstLoad] = useState(true)

  const [bgClass, setBgClass] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      console.log('END')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getProject() {
      console.log('get project')

      const docRef = doc(db, 'solargraf-projects', params.id)
      const docSnap = await getDoc(docRef)
      if(docSnap.exists() && mounted.current) {
        setProject(docSnap.data())
      } else {
        setProject(null)
      }
    }
    if(params.id && !project) getProject()
  }, [params.id])

  useEffect(() => {
    async function getContact() {
      const contactEmail = project.client.email

      const q = query(collection(db, 'contacts'), where('email', '==', contactEmail))
      const snapshot = await getDocs(q)

      if(mounted.current) {
        if(snapshot.docs.length > 0) {
          setContact({
            id: snapshot.docs[0].id,
            ...snapshot.docs[0].data()
          })
        } else {
          setContact(null)
        }
      }
    }

    async function getSalesRep() {
      console.log('get sales rep')
      const repEmail = project.owner.email

      const q = query(collection(db, 'contacts'), where('email', '==', repEmail))
      const snapshot = await getDocs(q)

      if(mounted.current) {
        if(snapshot.docs.length > 0) {
          setSalesRep(snapshot.docs[0].data())
        } else {
          setSalesRep(null)
        }
      }
    }

    if(project && !contact) {
      getContact()
      if(project.proposals) {
        const proposalsUpdate = project.proposals.map(proposal => {
          const proposalUpdate = proposal
          delete proposalUpdate.costs
          return proposalUpdate
        })
        setProposals(project.proposals)
      }
      const projectUpdate = project
      delete projectUpdate.proposals
      setProject(projectUpdate)
      getSalesRep()
    }
  }, [project])

  useEffect(() => {
    async function getDeal() {
      console.log('get deal')

      const sendMessage = httpsCallable(functions, 'hubspotGetAssociatedDeals')
      await sendMessage({ text: JSON.stringify({
        id: contact.id,
        params: ['vimeo_url', 'solargraf_url']
      })})
      .then(result => {
        console.log('deal result', result)
        let currentDeal = null
        if(mounted.current) {
          console.log('deals', result.data)
          if(result.data && (result.data.length > 0)) {
            const find = result.data.find(d => d.properties.vimeo_url)

            if(find) setDeal(find)
            else setDeal(null)
          }
        }
      })
      .catch(e => {
        console.log(e)
        if(mounted.current) setDeal(null)
      })
    }

    // if(params.id && contact && (deal === false)) getDeal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact])

  console.log('sales rep', salesRep)
  console.log('current index', currentIndex)
  console.log('project', project)
  console.log('proposals', proposals)
  console.log('neighbors', neighbors)
  console.log('deal', deal)
  console.log('contact', contact)

  useEffect(() => {
    async function getNeighbors() {
      console.log('get neighbors')
      const coordinates = [parseFloat(project.address.longitude), parseFloat(project.address.latitude)]

      const sendMessage = httpsCallable(functions, 'mongoQuery')
      await sendMessage({ text: JSON.stringify({
        database: 'astrawatt',
        collection: 'installs',
        find: {
          geoJson: {
            $near:
            {
              $geometry: { type: 'Point',  coordinates: coordinates },
              $minDistance: 0,
              $maxDistance: 100000
            }
          }
        },
        limit: 0
      })})
      .then(result => {
        if(mounted.current) setNeighbors(result.data)
      })
      .catch(e => {
        console.log(e)
        if(mounted.current) setNeighbors(null)
      })
    }
    if(contact) getNeighbors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact])

  useEffect(() => {
    if(contact && salesRep) {
      console.log('set current index')
      if(params.section) {
        const find = sections.findIndex(section => section.slug === params.section)
        if(find) {
          setCurrentIndex(find)
        } else {
          setCurrentIndex(0)
        }
      } else {
        setCurrentIndex(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, salesRep])

  useEffect(() => {
    if(currentIndex || currentIndex === 0) {
      const section = sections[currentIndex]
      const path = '/proposal/' + params.id + '/' + section.slug
      if(window.location.pathname !== path) {
        window.history.replaceState(null, section.label, path)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])

  useEffect(() => {
    if(firstLoad && contact && salesRep && project && deal && (currentIndex || currentIndex === 0)) {
      if(mounted.current) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant'
        })
        updateFill()
        setFirstLoad(false)
        if(currentIndex === 0) {
          setTimeout(() => {
            if(mounted.current) {
              setRun(true)
            }
          }, 1800)
        }
      }
    }
  }, [firstLoad, contact, salesRep, project, deal, currentIndex])

  function updateFill() {
    setTimeout(() => {
      if(document.querySelector('[data-fill-class]')) {
        const fill = document.querySelector('[data-fill-class]').dataset.fillClass
        setBgClass(fill)
      } else {
        setBgClass(false)
      }
    }, 100)
  }

  function handleJoyrideCallback(data) {
    const { action, index, status, type } = data
    console.log(data)
  }

  if(contact && salesRep && project && deal && (currentIndex || currentIndex === 0)) {
    return (
      <LayoutTransition>
        <Joyride
          callback={handleJoyrideCallback}
          tooltipComponent={Tooltip}
          steps={steps}
          run={run}
          continuous={true}
          disableScrolling={true}
          styles={{
            options: {
              arrowColor: 'rgb(214 252 119)'
            },
            tooltip: {
              animation: 'fadeIn 440ms ease'
            }
          }}
        />
        <div className={(bgClass ? bgClass : 'bg-white') + ' min-h-screen transition duration-700'} key='contact-content'>
          <div className='fixed top-6 left-6 z-10'>
            <svg fill='currentColor' className='h-8' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 627.03 145.65'>
              <path className={'transition duration-700 fill-black'} d='m251.73,52.89l-4.86,12.98h-12.42l20.39-54.88h12.66l20.31,54.88h-12.27l-4.86-12.98h-18.96Zm15.21-10.12l-5.82-19.12-5.57,19.12h11.39Z'></path>
              <path className={'transition duration-700 fill-black'} d='m302.14,50.58c-11.15-3.03-11.23-8.68-11.15-12.11.08-5.5,6.69-10.59,14.5-10.59s15.21,3.27,15.29,12.35h-10.43c-.08-2.79-2.47-3.27-4.22-3.27-2.39,0-3.98,1.11-3.9,2.79.08,1.11,1.03,2.23,3.27,2.79l6.29,1.67c9,2.47,10.12,8.44,10.12,10.83,0,7.88-8.28,11.63-16.09,11.63s-15.45-4.54-15.53-12.82h10.35c.08,2.15,2.47,4.3,5.5,4.3,2.47-.08,4.54-1.2,4.54-3.11,0-1.59-.96-2.47-3.66-3.19l-4.86-1.27Z'></path>
              <path className={'transition duration-700 fill-black'} d='m351.99,28.68v8.76h-7.81v28.43h-10.99v-28.43h-6.77v-8.76h6.77v-14.26h10.99v14.26h7.81Z'></path>
              <path className={'transition duration-700 fill-black'} d='m369.82,65.87h-11.07V28.68h11.07v9c1.91-6.93,6.69-9.8,10.51-9.8,2.63,0,4.62.48,6.37,1.35l-1.99,9.96c-1.99-.96-3.9-1.12-5.65-1.12-6.37,0-9.24,5.02-9.24,14.81v12.98Z'></path>
              <path className={'transition duration-700 fill-black'} d='m419.35,61.65c-2.39,3.58-7.96,4.94-10.83,4.94-9.48,0-18.96-7.41-18.96-19.35s9.48-19.35,18.96-19.35c2.39,0,7.96.8,10.83,4.94v-4.14h10.99v37.2h-10.99v-4.22Zm-9-5.57c4.54,0,9-3.27,9-8.84s-4.46-8.84-9-8.84-9.32,3.51-9.32,8.84,4.7,8.84,9.32,8.84Z'></path>
              <path className={'transition duration-700 fill-black'} d='m482.34,65.87h-9.24l-7.33-23.65-7.01,23.65h-9.24l-13.46-37.2h11.79l6.61,22.14,6.53-22.14h9.48l6.53,22.14,6.61-22.14h11.79l-13.06,37.2Z'></path>
              <path className={'transition duration-700 fill-black'} d='m528.29,61.65c-2.39,3.58-7.96,4.94-10.83,4.94-9.48,0-18.96-7.41-18.96-19.35s9.48-19.35,18.96-19.35c2.39,0,7.96.8,10.83,4.94v-4.14h10.99v37.2h-10.99v-4.22Zm-9-5.57c4.54,0,9-3.27,9-8.84s-4.46-8.84-9-8.84-9.32,3.51-9.32,8.84,4.7,8.84,9.32,8.84Z'></path>
              <path className={'transition duration-700 fill-black'} d='m571.76,28.68v8.76h-7.81v28.43h-10.99v-28.43h-6.77v-8.76h6.77v-14.26h10.99v14.26h7.81Z'></path>
              <path className={'transition duration-700 fill-black'} d='m600.83,28.68v8.76h-7.81v28.43h-10.99v-28.43h-6.77v-8.76h6.77v-14.26h10.99v14.26h7.81Z'></path>
              <circle className={'transition duration-700 fill-black'} cx='36.41' cy='72.82' r='36.41'></circle>
              <path className={'transition duration-700 ' + (bgClass ? 'fill-black' : 'fill-primary')} d='m250.14,116.38c.16,3.5,2.95,6.61,8.2,6.61,4.7,0,7.96-2.23,8.04-5.81.08-2.47-1.03-4.62-6.61-6.21l-6.61-1.83c-12.11-3.74-14.18-10.83-14.18-15.77,0-9.8,8.36-16.41,18.88-16.41s18.56,6.29,18.56,16.81h-11.63c0-3.66-2.47-6.21-7.09-6.21-4.06,0-7.09,2.23-7.09,5.5,0,1.43.56,4.06,5.89,5.57l6.53,2.07c13.3,3.82,15.21,11.31,15.05,16.57-.16,10.91-10.04,16.09-19.75,16.09-11.95,0-19.91-7.09-19.91-16.96h11.71Z'></path>
              <path className={'transition duration-700 ' + (bgClass ? 'fill-black' : 'fill-primary')} d='m284.14,113.76c0-11.71,8.76-19.27,19.27-19.27s19.35,7.57,19.35,19.27-8.84,19.35-19.35,19.35-19.27-7.65-19.27-19.35Zm27.72,0c0-5.02-3.82-8.44-8.44-8.44s-8.36,3.43-8.36,8.44,3.82,8.52,8.36,8.52,8.44-3.34,8.44-8.52Z'></path>
              <path className={'transition duration-700 ' + (bgClass ? 'fill-black' : 'fill-primary')} d='m341.79,75.05v57.43h-11.07v-57.43h11.07Z'></path>
              <path className={'transition duration-700 ' + (bgClass ? 'fill-black' : 'fill-primary')} d='m379.54,128.25c-2.39,3.58-7.96,4.94-10.83,4.94-9.48,0-18.96-7.41-18.96-19.35s9.48-19.35,18.96-19.35c2.39,0,7.96.8,10.83,4.94v-4.14h10.99v37.2h-10.99v-4.22Zm-9-5.57c4.54,0,9-3.27,9-8.84s-4.46-8.84-9-8.84-9.32,3.51-9.32,8.84,4.7,8.84,9.32,8.84Z'></path>
              <path className={'transition duration-700 ' + (bgClass ? 'fill-black' : 'fill-primary')} d='m411.78,132.47h-11.07v-37.2h11.07v9c1.91-6.93,6.69-9.8,10.51-9.8,2.63,0,4.62.48,6.37,1.35l-1.99,9.96c-1.99-.96-3.9-1.12-5.65-1.12-6.37,0-9.24,5.02-9.24,14.81v12.98Z'></path>
              <polygon className={'transition duration-700 ' + (bgClass ? 'fill-black' : 'fill-primary')} points='142.22 0 86.74 88.08 142.22 88.08 142.22 145.65 197.7 57.57 142.22 57.57 142.22 0'></polygon>
              <path className={'transition duration-700 fill-black'} d='m600.83,2.68V0h9.58v2.68h-3.26v9.95h-3.06V2.68h-3.26Z'></path>
              <path className={'transition duration-700 fill-black'} d='m622.91,0h2.58l1.54,12.63h-3.15l-.77-6.8-2.55,5.33h-2.31l-2.57-5.33-.79,6.8h-3.15l1.56-12.63h2.55l3.56,7.68,3.5-7.68Z'></path>
            </svg>
          </div>

          <div className='fixed top-6 right-6 flex items-center gap-2 z-20'>
            <p className='text-sm font-bold'>{contact && contact.firstname && toTitleCase(contact.firstname)} {contact && contact.lastname && toTitleCase(contact.lastname)}</p>
            <Link
              to='/'
              className={(bgClass ? 'ring-2 ring-inset ring-black' : 'bg-gray-200') + ' z-10 group flex items-center justify-center duration-700 rounded-full h-10 w-10'}>
              <HiOutlineXMark strokeWidth={2.25} className='transition-[transform] transform duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </Link>
          </div>

          <AnimatePresence
            exitBeforeEnter
            mode='wait'
            onExitComplete={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant'
              })
              updateFill()
            }}>
            {sections.map((section, index) => {
              if(currentIndex === index) {
                return (
                  <LayoutTransition key={section.label}>
                    <div id={section.slug}>
                      {section.element({ project, proposals, currentIndex, index, section, contact, salesRep, neighbors })}
                    </div>
                  </LayoutTransition>
                )
              } else {
                return false
              }
            })}
          </AnimatePresence>

          <Nav bgClass={bgClass} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
        </div>
      </LayoutTransition>
    )
  } else if(contact === null && salesRep === null && deal === null && currentIndex === null && currentIndex === null) {
    return (
      <LayoutTransition key='all'>
        <div className='relative h-screen w-screen flex flex-col gap-6 items-center justify-center'>
          <p>Sorry...we couldn't find the contact, sales representative, deal, project, or section associated with this lead.</p>
          <a className='inline-block bg-primary rounded-full px-4 py-2 font-bold transition duration-200 hover:bg-gray-200' href='https://app.hubspot.com/contacts/3446639/' target='_blank'>Check HubSpot</a>
          <a className='inline-block bg-primary rounded-full px-4 py-2 font-bold transition duration-200 hover:bg-gray-200' href='https://app.solargraf.com' target='_blank'>Create a project in Solargraf</a>
          <div className='fixed top-6 right-6 flex items-center gap-2 z-20'>
            <Link
              to='/'
              className='z-10 group flex items-center justify-center transition-all duration-700 rounded-full h-10 w-10 bg-gray-200 hover:bg-gray-150'>
              <HiOutlineXMark strokeWidth={2.125} className='transition-[transform] duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </Link>
          </div>
        </div>
      </LayoutTransition>
    )
  } else if(contact === null) {
    return (
      <LayoutTransition key='contact'>
        <div className='relative h-screen w-screen flex flex-col gap-6 items-center justify-center'>
          <p>Sorry...we couldn't find this contact.</p>
          <a className='inline-block bg-primary rounded-full px-4 py-2 font-bold transition duration-200 hover:bg-gray-200' href='https://app.hubspot.com/contacts/3446639/' target='_blank'>Check HubSpot</a>
          <div className='fixed top-6 right-6 flex items-center gap-2 z-20'>
            <Link
              to='/'
              className='z-10 group flex items-center justify-center transition-all duration-700 rounded-full h-10 w-10 bg-gray-200 hover:bg-gray-150'>
              <HiOutlineXMark strokeWidth={2.125} className='transition-[transform] duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </Link>
          </div>
        </div>
      </LayoutTransition>
    )
  } else if(salesRep === null) {
    return (
      <LayoutTransition key='salesRep'>
        <div className='relative h-screen w-screen flex flex-col gap-3'>
          <div className='max-w-lg flex flex-col gap-6 mx-auto py-24'>
            <h2 className='font-bold text-3xl'>Please Update Hubspot</h2>
            <p className='text-lg max-w-md'><span className='font-bold'>First</span> — you need to create a HubSpot contact record that matches your user email.</p>

            <div className='px-4 py-6 border rounded-2xl bg-gray-50'>
              <ol className='list-decimal list-inside text-sm space-y-1'>
                <li>Create a contact in HubSpot.</li>
                <li>Make sure that contact has the same email address as your HubsSpot user login.</li>
                <li>Make sure to fill out your first name, last name, job title, and phone number.</li>
                <li>Your phone number must be in the format +1##########.</li>
              </ol>
              <p className='pt-3 text-sm text-gray-700'>This is the way we can keep track of your contact information in HubSpot and use it with our non-HubSpot tools.</p>
            </div>
            <p><a className='inline-block bg-primary rounded-full px-4 py-1.5 font-bold transition duration-200 hover:bg-gray-200' href='https://app.hubspot.com/contacts/3446639/' target='_blank'>Create a Contact</a></p>

            <div>
              <p><span className='font-bold'>Next steps</span> — verify your phone number with Twilio &mdash; go to this web page and follow the instructions.</p>
              <p className='pt-2 text-sm text-gray-500'>Don't remember verifyng? It doesn't hurt to do it again — just in case!</p>
            </div>

            <p><a className='inline-block bg-primary rounded-full px-4 py-1.5 font-bold transition duration-200 hover:bg-gray-200' href='https://astrawatt.solar/tools/lightspeed-dialer' target='_blank'>Verify My Number</a></p>
          </div>

          <div className='fixed top-6 right-6 flex items-center gap-2 z-20'>
            <Link
              to='/'
              className='z-10 group flex items-center justify-center transition-all duration-700 rounded-full h-10 w-10 bg-gray-200 hover:bg-gray-150'>
              <HiOutlineXMark strokeWidth={2.125} className='transition-[transform] duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </Link>
          </div>
        </div>
      </LayoutTransition>
    )
  } else if(currentIndex === null) {
    return (
      <LayoutTransition key='index'>
        <div className='relative h-screen w-screen flex flex-col gap-6 items-center justify-center'>
          <p>Sorry...we couldn't find the section.</p>
          <a className='inline-block bg-primary rounded-full px-4 py-2 font-bold transition duration-200 hover:bg-gray-200' href='https://app.hubspot.com/contacts/3446639/' target='_blank'>Check HubSpot</a>
          <div className='fixed top-6 right-6 flex items-center gap-2 z-20'>
            <Link
              to='/'
              className='z-10 group flex items-center justify-center transition-all duration-700 rounded-full h-10 w-10 bg-gray-200 hover:bg-gray-150'>
              <HiOutlineXMark strokeWidth={2.125} className='transition-[transform] duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </Link>
          </div>
        </div>
      </LayoutTransition>
    )
  } else if(deal === null) {
    return (
      <LayoutTransition key='index'>
        <div className='relative h-screen w-screen flex flex-col gap-6 items-center justify-center'>
          <p>Sorry...we couldn't find the associated deal for this contact.</p>
          <a className='inline-block bg-primary rounded-full px-4 py-2 font-bold transition duration-200 hover:bg-gray-200' href='https://app.hubspot.com/contacts/3446639/' target='_blank'>Check HubSpot</a>
          <div className='fixed top-6 right-6 flex items-center gap-2 z-20'>
            <Link
              to='/'
              className='z-10 group flex items-center justify-center transition-all duration-700 rounded-full h-10 w-10 bg-gray-200 hover:bg-gray-150'>
              <HiOutlineXMark strokeWidth={2.125} className='transition-[transform] duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </Link>
          </div>
        </div>
      </LayoutTransition>
    )
  } else if(project === null) {
    return (
      <LayoutTransition key='project'>
        <div className='relative h-screen w-screen flex flex-col gap-6 items-center justify-center'>
          <p className='max-w-sm text-center text-xl'>Sorry...we couldn't find the project associated with this lead in Solargraf.</p>
          <a className='inline-block bg-primary rounded-full px-4 py-2 font-bold transition duration-700 hover:bg-gray-200' href='https://app.solargraf.com' target='_blank'>Create a project in Solargraf</a>
          <div className='fixed top-6 right-6 flex items-center gap-2 z-20'>
            <Link
              to='/'
              className='z-10 group flex items-center justify-center transition-all duration-700 rounded-full h-10 w-10 bg-gray-200 hover:bg-gray-150'>
              <HiOutlineXMark strokeWidth={2.125} className='transition-[transform] duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </Link>
          </div>
        </div>
      </LayoutTransition>
    )
  }

  if((contact === false) || (salesRep === false) || (deal === false) || (currentIndex !== false) || (project === false)) {
    return (
      <LayoutTransition>
        <div className='relative h-screen w-screen flex flex-col items-center py-24 justify-center'>
          <div className='fixed top-6 right-6 flex items-center gap-2 z-20'>
            <Link
              to='/'
              className='z-10 group flex items-center justify-center transition-all duration-700 rounded-full h-10 w-10 bg-gray-200 hover:bg-gray-150'>
              <HiOutlineXMark strokeWidth={2.125} className='transition duration-700 group-hover:rotate-90 h-5 w-5 m-auto' />
            </Link>
          </div>
          <p><span className='font-bold'>Loading</span> &mdash; locating {[{value:contact,label:'contact'},{value:salesRep,label:'sales representative'},{value:deal,label:'deal'},{value:project,label:'project'}].filter(i => i.value === false).map(i => i.label).join(', ')}...</p>
        </div>
      </LayoutTransition>
    )
  }
}

const Tooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps
}) => {
  return (
  <div {...tooltipProps} className='max-w-xs bg-secondary rounded-xl p-3'>
    {step.title && <div className='font-bold mb-3'>{step.title}</div>}
    <div className='mb-6'>{step.content}</div>
    <div className='flex'>
      <div className='flex-1'>
        {!continuous || (index < steps.length - 1) &&
          <button className='bg-white font-bold px-4 py-1 rounded-full' {...skipProps}>
            Skip
          </button>
        }
      </div>
      <div className='flex gap-1'>
        {index > 0 &&
          <button className='bg-white font-bold px-4 py-1 rounded-full' {...backProps}>
            Back
          </button>
        }
        {index !== steps.length - 1 &&
          <button className='bg-black font-bold text-white px-4 py-1 rounded-full' {...primaryProps}>
            Next
          </button>
        }
        {index === steps.length - 1 &&
          <button className='bg-black font-bold text-white px-4 py-1 rounded-full' {...closeProps}>
            Close
          </button>
        }
      </div>
    </div>
  </div>
)
}

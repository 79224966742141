import { motion } from 'framer-motion'
import Logo from 'components/logo/logo'

export default function Index({ project, proposals, production, materials, financing, drawings, active, index }) {
  return (
    <div data-fill-class='bg-gray-350' className='px-6 py-24 flex flex-col items-center'>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, duration: .4 }}>
        <p className='text-center font-mono uppercase mb-4'>Thank You</p>
        <h1 className='font-body font-bold text-4xl max-w-3xl text-center mb-12'>Grab our <span className='underline'>stellar promotional offer</span> &mdash; get a free EV charger + installation this May.</h1>
      </motion.div>
      <div className='space-y-10 text-center mb-16'>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}
          className='max-w-3xl bg-gray-250 mx-auto aspect-video md:aspect-[16/7] lg:aspect-[16/5] rounded-full'>
          <img loading='lazy' alt='Joing our referral network.' className='block h-full w-full object-cover rounded-full overflow-hidden' src='/images/ev-charger.jpg' />
        </motion.div>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}>
          <a className='inline-block mx-auto hover:bg-opacity-60 transition duration-200 bg-gray-100 font-bold p-6 py-1.5 rounded-full' href='/'>Claim my offer</a>
        </motion.div>
      </div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}>
        <p className='text-center font-mono uppercase mb-4'>Earn Cash</p>
        <h1 className='font-body font-bold text-4xl max-w-3xl text-center mb-12'>Join our referral crew &mdash; invite your friends &amp; <span className='underline'>start earning money</span> today.</h1>
      </motion.div>
      <div className='space-y-10 text-center'>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}
          className='max-w-4xl bg-gray-250 mx-auto aspect-video md:aspect-[16/7] lg:aspect-[16/5] rounded-full'>
          <img loading='lazy' alt='Joing our referral network.' className='block h-full w-full object-cover rounded-full overflow-hidden' src='/images/network-3.jpg' />
        </motion.div>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}>
          <a className='inline-block mx-auto hover:bg-opacity-60 transition duration-200 bg-gray-100 font-bold p-6 py-1.5 rounded-full' href='/'>Join the network</a>
        </motion.div>
      </div>
    </div>
  )
}

import { useRef, useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import { getFunctions, httpsCallable } from 'firebase/functions'

import { Context } from 'store/index'

import Map from './components/map'
import LayoutTransition from 'components/transitions/layout'

import { HiOutlineXMark } from 'react-icons/hi2'

export default function Index() {
  const [state] = useContext(Context)

  const functions = getFunctions()

  const [locations, setLocations] = useState(false)
  const [location, setLocation] = useState(false)

  useEffect(() => {
    async function getLocations() {
      console.log('get locations')
      const sendMessage = httpsCallable(functions, 'mongoQuery')
      await sendMessage({ text: JSON.stringify({
        database: 'astrawatt',
        collection: 'locations',
        limit: 0
      })})
      .then(result => {
        setLocations(result.data)
      })
      .catch(e => {
        console.log(e)
      })
    }
    getLocations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(locations) {
      setLocation(locations.find(location => location.name === 'Kansas City'))
    }
  }, [locations])

  return (
    <LayoutTransition>
      <div className='h-screen w-screen relative'>
        <div className='fixed top-3 right-3 flex items-center gap-2 z-40'>
          <Link
            to='/'
            className='z-10 group flex items-center justify-center transition-all duration-200 rounded-full h-10 w-10 bg-gray-200 hover:bg-gray-150'>
            <HiOutlineXMark strokeWidth={2.125} className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </Link>
        </div>
        <Map location={location} setLocation={setLocation} locations={locations} />
      </div>
    </LayoutTransition>
  )
}

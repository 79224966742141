import { BrowserRouter as Router } from 'react-router-dom'

import Store from 'store/index'

import DarkMode from 'components/dark-mode'
import Routes from 'components/routes'

export default function Index() {
  return (
    <Store>
      <Router>
        <DarkMode />
        <Routes />
      </Router>
    </Store>
  )
}

import Logo from 'components/logo/logo'

import { motion } from 'framer-motion'

const reasons = [
  {
    title: 'The cost of electricity is going up.',
    heading: 'By installing solar panels on your home, you can avoid the rising costs of electricity by producing and owning your own electricity.',
    description:
      <ul className='bg-primary-250 px-3 py-6 rounded-2xl'>
        <li>Infrastructure maintenance</li>
        <li>Fuel costs</li>
        <li>Growing demand</li>
        <li>Inflation</li>
        <li>Regulatory policies</li>
      </ul>
  },
  {
    title: 'Utility companies send the "bill of shame".',
    heading: 'Your utility company wants you to use less electricity, serve more customers, and increase rates to generate more revenue while mitigating their own costs.',
    description:
      <ul className='bg-primary-250 px-3 py-6 rounded-2xl'>
        <li>Encourage conservation</li>
        <li>Reduce the pain of rising energy costs</li>
        <li>Encourage efficiency upgrades</li>
        <li>Increase revenue</li>
      </ul>
  },
  {
    title: 'The cost of going solar is decreasing.',
    heading: 'As technology advances, production of solar hardware ramps up, and more of your neighbors get installations — the cost decreases. Solar is one of the best investments you can make for your home.',
    description:
      <ul className='bg-primary-250 px-3 py-6 rounded-2xl'>
        <li>Technological improvements</li>
        <li>Economies of scale</li>
        <li>Increased competition</li>
        <li>Policy support and incentives</li>
      </ul>
  }
]
export default function Index({ project, proposals, production, materials, financing, drawings, active, index }) {
  return (
    <div className='px-6 py-24 flex flex-col items-center'>
      <motion.div
        className='text-center'
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, duration: .4 }}>
        <p className='font-mono uppercase mb-10'>Why Solar</p>
        <h1 className='font-body font-bold text-3xl max-w-3xl mb-20'>Key considerations &mdash; a few of our favorite reasons why <span className='text-primary'>going solar now</span> is a great move.</h1>
      </motion.div>
      <ul className='space-y-24'>
        {reasons.map((reason, index) =>
          <motion.li
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}
            viewport={{ once: true }}
            key={index + '-reason'}
            className='flex flex-col gap-3'>
            <h2 className='text-center flex-1 font-bold text-2xl'>{reason.title}</h2>
            <p className='mb-8 max-w-2xl text-center text-lg'>{reason.heading}</p>
            <div className='w-full max-w-lg mx-auto'>{reason.description}</div>
          </motion.li>
        )}
      </ul>
    </div>
  )
}

import 'firebase.js'

import { createRoot } from 'react-dom/client'

import 'style/index.sass'

import App from 'app'

createRoot(document.getElementById('root')).render(
  <App />
)

import { useRef, useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import { motion } from 'framer-motion'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, onSnapshot, orderBy } from 'firebase/firestore'

import { useDebouncedCallback } from 'use-debounce'
import pluralize from 'pluralize'

import Profile from 'components/menu/profile'
// import Cluster from 'components/menu/cluster'
import Nav from 'components/menu/nav'
import LayoutTransition from 'components/transitions/layout'
import Logo from 'components/logo/logo-lightsale'

import { Context } from 'store/index'
import { toTitleCase } from 'lib/utils'

import { HiChatBubbleOvalLeft, HiOutlineMagnifyingGlass, HiOutlineArrowRight, HiBolt, HiMapPin } from 'react-icons/hi2'

const twilioAccountSid = 'AC4511c24c78adbfdbae3718ee5f56c7d3'
const twilioAuthToken = 'c3d7ba339f77c443c3a884db741a7c78'

export default function Index() {
  const [state] = useContext(Context)

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(false)
  const proposalsUnsub = useRef(false)

  const [search, setSearch] = useState('')

  const [proposals, setProposals] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(proposalsUnsub.current) proposalsUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(search) debouncedGetProposals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const debouncedGetProposals = useDebouncedCallback(() => {
    getProposals()
  }, 1000)

  useEffect(() => {
    if(search) {
      debouncedGetProposals()
    } else if(search !== false) {
      getProposals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  async function getProposals() {
    if(proposalsUnsub.current) proposalsUnsub.current()

    let q = null
    if(search && search !== '') {
      q = query(collection(db, 'solargraf-projects'), orderBy('createdAt', 'desc'), where('keyWords', 'array-contains', search.toLowerCase()), limit(12))
    } else {
      q = query(collection(db, 'solargraf-projects'), orderBy('createdAt', 'desc'), limit(12))
    }

    if(q) {
      proposalsUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            setProposals(querySnapshot.docs.map(doc => {
              const c = {
                id: doc.id
              }
              Object.entries(doc.data()).forEach(([key, value]) => {
                if(value instanceof Timestamp) {
                  c[key] = value.seconds * 1000 + value.nanoseconds / 1000000
                } else {
                  c[key] = value
                }
              })
              if(c.proposals) {
                c.proposals = c.proposals.map(proposal => true)
              }
              return {
                vid: doc.id,
                ...c
              }
            }))
          } else {
            setProposals(null)
          }
        } else {
          setProposals(null)
        }
      })
    }
  }

  return (
    <LayoutTransition>
      <div>
        <div className='fixed items-center top-3 right-3 z-10 flex gap-2'>
          <Profile />
          {/* <Cluster /> */}
          <Nav />
        </div>
        <div className='fixed items-center top-3 left-3 z-10'>
          <Logo height={'h-8'} fontSize={'text-base'}/>
        </div>
        <div className='px-3 pt-24 pb-16 h-screen w-screen overflow-hidden flex flex-col gap-6 pb-16'>
          <motion.div
            className='overflow-scroll h-full border rounded-2xl'
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}>
            <div className='sticky top-0 p-3 z-10 bg-white'>
              <label htmlFor='search' className='hidden pl-8 block flex justify-center cursor-pointer'>
                <HiOutlineMagnifyingGlass strokeWidth={1.5} className='-mb-2 relative z-10 h-9 w-9' />
              </label>
              <input
                onChange={(e) => {
                  setProposals(false)
                  setSearch(e.target.value)
                }}
                id='search'
                className='text-sm w-full bg-gray-150 px-4 py-2.5 rounded-full flex-1 outline-none !placeholder-gray-700 appearance-none'
                placeholder='Search proposals...' />
            </div>
            {proposals === false &&
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}>
                <h2 className='px-3 py-6 font-bold'>
                  Looking for proposals...
                </h2>
              </motion.div>
            }
            {proposals === null &&
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}>
                <h2 className='px-3 py-6 font-bold'>
                  No proposals found.
                </h2>
              </motion.div>
            }
            {proposals &&
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}>
                <ul className='pt-6 pb-24 divide-y'>
                  {proposals.map(proposal => {
                    return (
                      <li key={'proposal-list-'+proposal.id} className='w-full'>
                        <div className='px-3 py-3 block flex gap-6 items-center w-full text-left group transition duration-200'>

                          <div className='flex gap-1.5'>
                            <Link
                              className='block bg-primary flex items-center justify-center h-8 w-8 rounded-full overflow-hidden relative'
                              to={'/proposal/' + proposal.id}>
                              <HiBolt className='h-3.5 w-3.5'/>
                            </Link>

                            {proposal.client.email &&
                              <Link
                                className='block bg-primary-250 flex items-center justify-center h-8 w-8 rounded-full overflow-hidden relative'
                                to={'/design/' + proposal.client.email}>
                                <HiMapPin className='h-3.5 w-3.5'/>
                              </Link>
                            }

                            <Link
                              className='hidden block border-2 border-gray-250 text-gray-250 flex items-center justify-center h-8 w-8 rounded-full overflow-hidden relative'
                              to={'/design/' + proposal.client.email}>
                              <HiChatBubbleOvalLeft className='h-3.5 w-3.5'/>
                            </Link>

                            {proposal.client.email &&
                              <a
                                target='_blank'
                                href={'https://app.hubspot.com/contacts/3446639/contact/' + proposal.client.email}
                                className='block border-2 border-gray-250 text-gray-250 flex items-center justify-center h-8 w-8 rounded-full overflow-hidden relative'>
                                <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' className='h-4 w-4 m-auto' viewBox='0 0 13.107 13.099' preserveAspectRatio='xMidYMid'><path d='M12.027 6.222a3.33 3.33 0 0 0-1.209-1.201c-.382-.222-.777-.363-1.223-.424V3a1.17 1.17 0 0 0 .722-1.097 1.2 1.2 0 0 0-1.2-1.206 1.21 1.21 0 0 0-1.21 1.206c0 .49.26.908.707 1.097v1.588a3.49 3.49 0 0 0-1.064.334L3.275 1.685c.03-.113.056-.23.056-.353 0-.738-.598-1.336-1.336-1.336S.66.594.66 1.332s.598 1.336 1.336 1.336c.252 0 .485-.074.686-.195l.28.212L6.797 5.45c-.203.186-.392.398-.543.636-.306.485-.493 1.018-.493 1.6v.12a3.35 3.35 0 0 0 .21 1.156c.116.316.286.604.497.864l-1.274 1.277c-.377-.14-.8-.047-1.085.238-.194.193-.303.456-.302.73s.108.535.303.73.456.303.73.303.537-.108.73-.303.303-.456.302-.73a1.03 1.03 0 0 0-.048-.31l1.316-1.316c.18.125.375.23.585.32a3.42 3.42 0 0 0 1.369.288h.09c.552 0 1.073-.13 1.562-.395a3.23 3.23 0 0 0 1.224-1.153c.307-.49.475-1.033.475-1.63v-.03c0-.587-.136-1.128-.42-1.624zM10.42 8.984c-.357.397-.768.642-1.232.642H9.1c-.265 0-.525-.073-.778-.207a1.8 1.8 0 0 1-.682-.621c-.184-.26-.284-.544-.284-.845v-.09c0-.296.057-.577.2-.842.153-.3.36-.515.635-.694s.558-.265.88-.265h.03c.29 0 .567.057.827.19a1.75 1.75 0 0 1 .65.591 1.88 1.88 0 0 1 .291.83l.007.187c0 .407-.156.784-.467 1.126z'></path></svg>
                              </a>
                            }

                            <a
                              target='_blank'
                              href={'https://app.solargraf.com/projects/' + proposal.id}
                              className='text-xs font-bold block border-2 border-gray-250 text-gray-250 flex items-center justify-center h-8 w-8 rounded-full overflow-hidden relative'>
                              SG
                            </a>
                          </div>

                          <div className='block truncate text-sm w-1/3'>
                            <Link
                              to={'/proposal/' + proposal.id}>
                              <span className='font-bold'>{proposal.client.name}</span><span className='text-gray-450'> &mdash; {proposal.name}</span>
                            </Link>
                          </div>
                          <div className='block truncate text-sm w-2/12 text-gray-450'>
                            <span>{proposal.client.email}</span>
                          </div>
                          <div className='block truncate text-sm flex-1 text-gray-450'>
                            <span>{proposal.owner.name}</span>
                          </div>
                          <div className='block truncate text-right text-sm flex-1 text-gray-450'>
                            <span>{proposal && proposal.proposals && pluralize('option', proposal.proposals.length, true)}</span>
                          </div>
                          <div className='block truncate text-right text-xs flex-1'>
                            {proposal.state === 'signed' &&
                              <span className='inline-block bg-primary px-3 py-1 rounded-full'>{toTitleCase(proposal.state)}</span>
                            }
                            {proposal.state !== 'signed' &&
                              <span className='inline-block bg-gray-200 px-3 py-1 rounded-full'>{toTitleCase(proposal.state)}</span>
                            }
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </motion.div>
            }
          </motion.div>
        </div>
      </div>
    </LayoutTransition>
  )
}

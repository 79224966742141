import { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { Context } from 'store/index'

import { HiOutlineXMark, HiUser } from 'react-icons/hi2'

import ProfileImage from './profile-image'

import { getAuth } from 'firebase/auth'

import Webcam from 'react-webcam'

export default function Index() {
  const [state] = useContext(Context)

  const [show, setShow] = useState(false)
  const [profile, setProfile] = useState(false)

  useEffect(() => {
    setProfile(state.profile)
  }, [state.profile])

  return (
    <div className='relative overflow-visible'>
      <button
        onClick={() => setShow(!show)}
        className='group bg-gray-200 flex h-10 w-10 transition duration-200 rounded-full hover:bg-gray-250'>
        <HiUser className='h-4 w-4 m-auto transition duration-200 transform group-hover:scale-125' />
      </button>

      <Modal profile={profile} showModal={show} setShowModal={setShow} />
    </div>
  )
}

function Modal({ profile, showModal, setShowModal }) {
  const functions = getFunctions()

  const [state] = useContext(Context)

  const firebaseApp = getApp()
  const auth = getAuth(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  return display ? createPortal(
    <div className='fixed z-40 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-white bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-full bg-gray-200 w-full max-w-xs transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='flex-1 overflow-scroll'>
          <div>
            <div className='p-2'>
              <button
                onClick={() => {
                  setShowModal(false)
                }}
                className='group flex h-9 w-9 rounded-full bg-gray-300 hover:bg-gray-350'>
                <HiOutlineXMark strokeWidth={2.125} className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
              </button>
            </div>
            <div className='relative p-2 space-y-6 py-6'>
              <ProfileImage />
              <WebcamStreamCapture />
              <ul className='flex-1 divide-y divide-gray-300 bg-gray-250 p-2 rounded-2xl'>
                <li className='flex items-center space-x-3 py-1.5'>
                  <div className='flex-1'>
                    <p className='text-gray-600 text-sm'>First Name</p>
                    <p className='truncate text-sm'>{profile && profile.firstName}</p>
                  </div>
                </li>
                <li className='flex items-center space-x-3 py-1.5'>
                  <div className='flex-1'>
                    <p className='text-gray-600 text-sm'>Last Name</p>
                    <p className='truncate text-sm'>{profile && profile.lastName}</p>
                  </div>
                </li>
                <li className='flex items-center space-x-3 py-1.5'>
                  <div className='flex-1'>
                    <p className='text-gray-600 text-sm'>Email</p>
                    <p className='truncate text-sm'>{profile && profile.email}</p>
                  </div>
                </li>
              </ul>
              <button
                className='font-bold text-black bg-primary hover:brightness-110 transition duration-200 py-1.5 text-sm rounded-full px-8'
                onClick={() => {
                  setShowModal(false)
                  auth.signOut()
                }}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  , document.body) : null
}

function WebcamStreamCapture() {
  const webcamRef = useRef(null)
  const mediaRecorderRef = useRef(null)
  const [capturing, setCapturing] = useState(false)
  const [recordedChunks, setRecordedChunks] = useState([])

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true)
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: 'video/webm'
    })
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable
    )
    mediaRecorderRef.current.start()
  }, [webcamRef, setCapturing, mediaRecorderRef])

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data))
      }
    },
    [setRecordedChunks]
  )

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop()
    setCapturing(false)
  }, [mediaRecorderRef, webcamRef, setCapturing])

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/webm'
      })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.href = url
      a.download = 'react-webcam-stream-capture.webm'
      a.click()
      window.URL.revokeObjectURL(url)
      setRecordedChunks([])
    }
  }, [recordedChunks])

  return (
    <>
      <Webcam audio={true} muted={true} ref={webcamRef} />
      {capturing ? (
        <button onClick={handleStopCaptureClick}>Stop Capture</button>
      ) : (
        <button onClick={handleStartCaptureClick}>Start Capture</button>
      )}
      {recordedChunks.length > 0 && (
        <button onClick={handleDownload}>Download</button>
      )}
    </>
  )
}

import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import sections from '../sections'
import { motion } from 'framer-motion'

import { XMarkIcon, BookOpenIcon } from '@heroicons/react/24/outline'

export default function Index({ bgClass, setCurrentIndex }) {
  const [show, setShow] = useState(false)

  return (
    <div className='fixed bottom-6 left-6 z-20 menu-button'>
      <motion.div
        initial={{ x: -10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -10, opacity: 0 }}
        transition={{ x: { type: 'spring', stiffness: 200 }, delay: .8, duration: .4 }}
        className='relative overflow-visible'>
        <button
          onClick={() => setShow(!show)}
          className={(bgClass ? 'ring-2 ring-inset ring-black' : 'bg-gray-200') + ' group flex h-10 w-10 transition duration-700 rounded-full'}>
          <BookOpenIcon strokeWidth={2.25} className='h-5 w-5 m-auto transition-[transform] duration-200 transform group-hover:scale-110' />
        </button>
        <Modal setCurrentIndex={setCurrentIndex} showModal={show} setShowModal={setShow} />
      </motion.div>
    </div>
  )
}

function Modal({ setCurrentIndex, showModal, setShowModal }) {
  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 300, damping: 24 }
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
  }

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  return display ? createPortal(
    <div className='fixed z-40 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'h-full w-full transition duration-500 transform ' + (animate ? 'translate-y-0' : 'translate-y-full')}>
        <div className='absolute top-0 right-0 z-10'>
          <div className='p-6'>
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className='group flex h-10 w-10 rounded-full bg-black-800 text-white hover:brightness-110 transition duration-200'>
              <XMarkIcon strokeWidth={2.125} className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </button>
          </div>
        </div>
        <div className='px-5vw py-24 h-full w-full overflow-scroll'>
          <motion.ul
            initial={'closed'}
            animate={animate ? 'open' : 'closed'}
            variants={{
              open: {
                transition: {
                  type: 'spring',
                  bounce: 0,
                  duration: 0.7,
                  delayChildren: 0.4,
                  staggerChildren: 0.1
                }
              },
              closed: {
                transition: {
                  type: 'spring',
                  bounce: 0,
                  duration: 0.3
                }
              }
            }}
            className='space-y-8 text-center'>
            {sections.map((s, i) =>
              <motion.li variants={itemVariants} key={'menu-' + i}>
                <button
                  onClick={() => {
                    setShowModal(false)
                    setTimeout(() => {
                      setCurrentIndex(i)
                    }, 400)
                  }}
                  className='text-white hover:scale-110 hover:text-primary transition duration-200 font-bold text-4xl mx-auto'>
                  {s.label}
                </button>
              </motion.li>
            )}
          </motion.ul>
        </div>
      </div>
    </div>
  , document.body) : null
}

import { Routes, Route, useLocation } from 'react-router-dom'

import { AnimatePresence } from 'framer-motion'

import Login from 'views/auth/login'
import ForgotPassword from 'views/auth/forgot-password'

import LayoutTransition from 'components/transitions/layout'

export default function Index() {
  const location = useLocation()

  return (
    <LayoutTransition>
      <AnimatePresence mode='wait'>
        <Routes key={location.pathname + '-routes-not-authenticated'} location={location}>
          <Route path='/' element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
        </Routes>
      </AnimatePresence>
    </LayoutTransition>
  )
}

import { useRef, useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'

import { getApp } from 'firebase/app'
import { getFirestore, getDoc, doc, query, collection, where, getDocs } from 'firebase/firestore'

import { Context } from 'store/index'

import Map from './components/map'
import LayoutTransition from 'components/transitions/layout'

import { HiOutlineXMark } from 'react-icons/hi2'

export default function Index() {
  const [state] = useContext(Context)

  const mounted = useRef(null)

  const params = useParams()
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [contact, setContact] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getContact() {
      console.log('get contact')

      const contactEmail = params.id

      const q = query(collection(db, 'contacts'), where('email', '==', contactEmail))
      const snapshot = await getDocs(q)

      if(mounted.current) {
        if(snapshot.docs.length > 0) {
          setContact({
            id: snapshot.docs[0].id,
            ...snapshot.docs[0].data()
          })
        } else {
          setContact(null)
        }
      }
    }

    if(params.id) getContact()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  return (
    <LayoutTransition>
      <div className='h-screen w-screen relative'>
        <div className='fixed top-3 right-3 flex items-center gap-2 z-40'>
          <Link
            to='/'
            className='z-10 group flex items-center justify-center transition-all duration-200 rounded-full h-10 w-10 bg-white hover:bg-gray-100'>
            <HiOutlineXMark strokeWidth={2.125} className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </Link>
        </div>
        <Map contact={contact} />
      </div>
    </LayoutTransition>
  )
}

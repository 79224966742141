import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { AnimatePresence } from 'framer-motion'

import LayoutTransition from 'components/transitions/layout'
import TransitionFadeUp from 'components/transitions/fade-up'
import LoaderSpin from 'components/loaders/spin'

export default function Index() {
  const [email, setEmail] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [])

  function handleSubmit(e) {
    e.preventDefault()
    setLoading(true)
    // submit forgot password
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  }

  return (
    <LayoutTransition>
      <AnimatePresence mode='wait'>
        {!loading &&
          <TransitionFadeUp key='forgot-password-form'>
            <form
              onSubmit={handleSubmit}
              className='h-screen w-screen flex flex-col gap-6 items-center justify-center'>
              <h2 className='text-xl font-bold -mb-2'>Forgot password</h2>
              <div className='w-full max-w-xs space-y-2'>
                <input
                  className='input w-full'
                  type='text'
                  value={email}
                  onChange={({ target }) => {
                    setEmail(target.value)
                  }}
                  placeholder='Enter email'
                />
                {error && <p className='text-center p-3 border-2 border-gray-200 rounded-lg'>{error}</p>}
              </div>
              <button
                type='submit'
                className='text-black font-bold bg-primary hover:brightness-110 rounded-full w-44 py-2'>
                Send reset email
              </button>
              <Link
                to='/'
                className='text-sm'>
                Back to login
              </Link>
            </form>
          </TransitionFadeUp>
        }
        {loading &&
          <TransitionFadeUp key='login-loader'>
            <div className='h-screen w-screen flex items-center justify-center'>
              <LoaderSpin />
            </div>
          </TransitionFadeUp>
        }
      </AnimatePresence>
    </LayoutTransition>
  )
}

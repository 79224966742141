import { useRef, useState, useEffect } from 'react'

import { CheckIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import Logo from 'components/logo/logo'

import Swiper from 'swiper'
import 'swiper/css'

import { motion } from 'framer-motion'

import Briefing from '../components/svg/illustration/briefing.js'
import Sign from '../components/svg/illustration/sign.js'
import Finance from '../components/svg/illustration/finance.js'
import Survey from '../components/svg/illustration/survey.js'

import Inspection from '../components/svg/illustration/inspection.js'
import Launch from '../components/svg/illustration/launch.js'

const steps = [
  {
    title: 'Mission Breifing',
    description: 'The consultative process of discussing the solar energy objectives and blueprints for the customer\'s cosmic headquarters.',
    icon: <Briefing />
  },
  {
    title: 'Launch Agreement',
    description: 'Once the mission briefing is complete and the customer is ready to embark, the launch agreement is signed.',
    icon: <Sign />
  },
  {
    title: 'Expedition Funding',
    description: 'Payment or financing options are determined and approved for the solar voyage.',
    icon: <Finance />
  },
  {
    title: 'Site Survey',
    description: 'A technician is sent to the customer\'s property to scout and analyze the ideal solar panel deployment zones.',
    icon: <Survey />
  },
  {
    title: 'Stellar Blueprint & Engineering',
    description: 'Using the site survey the system design is confirmed, with the engineering stamps acquired and approved for the customer.',
    icon: <svg className='h-full w-full' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 94 94'><circle className='fill-primary' cx='47' cy='47' r='47'/><rect className='fill-white' x='29' y='17' width='36' height='60'/><rect className='fill-black-100' x='29' y='17' width='36' height='60'/><rect className='fill-white' x='32' y='20' width='30' height='60'/><rect className='fill-black-100' x='32' y='20' width='30' height='60'/><rect className='fill-white' x='29' y='17' width='36' height='20'/><rect className='fill-white' x='29' y='39' width='36' height='38'/><rect className='fill-black-100' x='60' y='25' width='2' height='9'/><rect className='fill-black-800' x='60' y='25' width='2' height='9'/><rect className='fill-black-800' x='60' y='42' width='2' height='19'/></svg>
  },
  {
    title: 'Clearance & Approval Acquisition',
    description: 'All required mission clearances are obtained through the city and grid interconnection applications are submitted.',
    icon: <svg className='h-full w-full' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 94 94'><circle className='fill-primary' cx='47' cy='47' r='47'/><rect className='fill-white' x='29' y='17' width='36' height='60'/><rect className='fill-black-100' x='29' y='17' width='36' height='60'/><rect className='fill-white' x='32' y='20' width='30' height='60'/><rect className='fill-black-100' x='32' y='20' width='30' height='60'/><rect className='fill-white' x='29' y='17' width='36' height='20'/><rect className='fill-white' x='29' y='39' width='36' height='38'/><rect className='fill-black-100' x='60' y='25' width='2' height='9'/><rect className='fill-black-800' x='60' y='25' width='2' height='9'/><rect className='fill-black-800' x='60' y='42' width='2' height='19'/></svg>
  },
  {
    title: 'System Assembly',
    description: 'Solar panels, inverters, and all necessary equipment are installed at the customer\'s cosmic headquarters.',
    icon: <svg className='h-full w-full' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 94 94'><circle className='fill-primary' cx='47' cy='47' r='47'/><rect className='fill-white' x='29' y='17' width='36' height='60'/><rect className='fill-black-100' x='29' y='17' width='36' height='60'/><rect className='fill-white' x='32' y='20' width='30' height='60'/><rect className='fill-black-100' x='32' y='20' width='30' height='60'/><rect className='fill-white' x='29' y='17' width='36' height='20'/><rect className='fill-white' x='29' y='39' width='36' height='38'/><rect className='fill-black-100' x='60' y='25' width='2' height='9'/><rect className='fill-black-800' x='60' y='25' width='2' height='9'/><rect className='fill-black-800' x='60' y='42' width='2' height='19'/></svg>

  },
  {
    title: 'Final Inspection',
    description: 'The installed solar system is inspected and approved by the relevant space authorities.',
    icon: <Inspection />

  },
  {
    title: 'Grid Synchronization',
    description: 'The solar system is connected to the local energy grid, forming a celestial bond.',
    icon: <svg className='w-full' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 94 94'><circle className='fill-primary' cx='47' cy='47' r='47'/><rect className='fill-white' x='29' y='17' width='36' height='60'/><rect className='fill-black-100' x='29' y='17' width='36' height='60'/><rect className='fill-white' x='32' y='20' width='30' height='60'/><rect className='fill-black-100' x='32' y='20' width='30' height='60'/><rect className='fill-white' x='29' y='17' width='36' height='20'/><rect className='fill-white' x='29' y='39' width='36' height='38'/><rect className='fill-black-100' x='60' y='25' width='2' height='9'/><rect className='fill-black-800' x='60' y='25' width='2' height='9'/><rect className='fill-black-800' x='60' y='42' width='2' height='19'/></svg>
  },
  {
    title: 'Ignition & Liftoff',
    description: 'The solar system is energized and begins generating clean, limitless energy to propel the customer\'s cosmic mission.',
    icon: <Launch />
  }
]

export default function Index({ project, proposals, production, materials, financing, drawings, active, index }) {
  return (
    <div className='px-6 py-24 flex flex-col items-center'>
      <motion.div
        className='text-center px-6'
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, duration: .4 }}>
        <p className='font-mono uppercase mb-8'>What We Do</p>
        <h1 className='font-body font-bold text-4xl max-w-3xl mb-16'>There are <span className='text-primary'>{steps.length} steps to going solar</span> &amp; we are here to guide you through them all.</h1>
      </motion.div>

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, delay: .2, duration: .4 }}>
        <div className='w-full space-y-6'>
          {steps.map((step, index) =>
            <div key={index + '-next'} className='relative max-w-lg'>
              {index % 2 !== 0 &&
                <>
                  {index !== (steps.length - 1) &&
                    <>
                      <LeftCenter />
                      <LeftDown />
                      <LeftWedge />
                      <LeftRoundTop />
                    </>
                  }
                  <RightCenter direction={'up'} />
                  <RightUp />
                  <RightRoundBottom />
                </>
              }
              {index % 2 === 0 &&
                <>
                  {index !== 0 &&
                    <>
                      <LeftCenter direction={'up'} />
                      <LeftUp />
                      <LeftRoundBottom />
                    </>
                  }
                  {index !== (steps.length - 1) &&
                    <>
                      <RightCenter direction={'down'} />
                      <RightDown />
                      <RightWedge />
                      <RightRoundTop />
                    </>
                  }
                </>
              }

              <div className={'w-full text-left flex flex-col p-3 pt-4 pb-6 flex flex-col gap-3 rounded-2xl overflow-hidden border-2 ' + (index === 0 ? 'border-gray-200' : 'border-gray-200')}>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ y: { type: 'spring', stiffness: 100 }, duration: .4, delay: .2 }}>
                  <p className='font-bold text-2xl mb-6'>
                    <span className='text-primary'>{String(index+1).padStart(2, '0')}</span> &mdash; {step.title}
                  </p>
                  <div className='w-32 mb-6'>{step.icon}</div>
                  <p className='max-w-full'>
                    {step.description}
                  </p>
                </motion.div>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  )
}

function RightCenter({ direction }) {
  if(direction === 'up') {
    return (
      <div className='absolute left-full top-1/2 transform -translate-y-full w-24 border-b-2 border-gray-200' />
    )
  }
  return (
    <div className='absolute left-full top-1/2 w-24 border-b-2 border-gray-200' />
  )
}
function RightUp() {
  return (
    <div className='absolute left-full ml-32 bottom-1/2 h-1/2 mb-8 transform -translate-x-full border-l-2 border-gray-200' />
  )
}
function RightDown() {
  return (
    <div className='absolute left-full ml-32 top-1/2 h-1/2 mt-8 transform -translate-x-full border-l-2 border-gray-200' />
  )
}
function RightWedge() {
  return (
    <div className='absolute left-full ml-32 top-full h-6 transform -translate-x-full border-l-2 border-gray-200' />
  )
}
function RightRoundTop() {
  return (
    <div className='absolute top-1/2 left-full ml-28 transform -translate-x-1/2 h-8 w-8 rounded-tr-full border-r-2 border-t-2 border-gray-200' />
  )
}
function RightRoundBottom() {
  return (
    <div className='absolute bottom-1/2 left-full ml-28 transform -translate-x-1/2  h-8 w-8 rounded-br-full border-r-2 border-b-2 border-gray-200' />
  )
}
function LeftCenter({ direction }) {
  if(direction === 'up') {
    return (
      <div className='absolute right-full top-1/2 w-24 border-b-2 transform -translate-y-full border-gray-200' />
    )
  }
  return (
    <div className='absolute right-full top-1/2 w-24 border-b-2 border-gray-200' />
  )
}
function LeftUp() {
  return (
    <div className='absolute right-full transform translate-x-full mb-8 mr-32 bottom-1/2 h-1/2 border-l-2 border-gray-200' />
  )
}
function LeftDown() {
  return (
    <div className='absolute right-full transform translate-x-full mr-32 mt-8 top-1/2 h-1/2 border-l-2 border-gray-200' />
  )
}
function LeftWedge() {
  return (
    <div className='absolute right-full transform translate-x-full mr-32 top-full h-6 border-l-2 border-gray-200' />
  )
}
function LeftRoundTop() {
  return (
    <div className='absolute top-1/2 right-full mr-28 transform translate-x-1/2 h-8 w-8 rounded-tl-full border-l-2 border-t-2 border-gray-200' />
  )
}
function LeftRoundBottom() {
  return (
    <div className='absolute bottom-1/2 right-full mr-28 transform translate-x-1/2  h-8 w-8 rounded-bl-full border-l-2 border-b-2 border-gray-200' />
  )
}

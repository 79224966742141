export function passwordStale(date) {
  const duration = 180 // 6 months
  const pastTime = new Date(date)
  const now = new Date()

  const thirtyDaysInMs = duration * 24 * 60 * 60 * 1000
  const timeDiffInMs = now.getTime() - pastTime.getTime()

  if(timeDiffInMs >= thirtyDaysInMs){
    // console.log('Password is stale')
    return true
  } else {
    // console.log('Password is fresh')
    return false
  }
}

export function toTitleCase(string) {
  return string.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}

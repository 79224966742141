import { useRef, useState, useEffect } from 'react'

import { PlusIcon, MinusIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { getFunctions, httpsCallable } from 'firebase/functions'

import pluralize from 'pluralize'

import * as L from 'leaflet'
import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

export default function Index({ location, setLocation, locations }) {
  const functions = getFunctions()

  const mapRef = useRef(null)
  const markerCluster = useRef(null)
  const currentTiles = useRef(null)

  const [map, setMap] = useState(false)
  const [type, setType] = useState(types[0])
  const [markers, setMarkers] = useState(false)
  const [clusters, setClusters] = useState(false)

  const [data, setData] = useState(false)

  const [center, _setCenter] = useState([39.0997, -94.5786])
  const centerRef = useRef(center)
  const setCenter = (data) => {
    centerRef.current = data
    _setCenter(data)
  }

  const [fitBounds, _setFitBounds] = useState(false)
  const fitBoundsRef = useRef(fitBounds)
  const setFitBounds = (data) => {
    fitBoundsRef.current = data
    _setFitBounds(data)
  }

  const [updateData, _setUpdateData] = useState(false)
  const setUpdateData = (data) => {
    _setUpdateData(data)
  }

  useEffect(() => {
    if(mapRef.current && !map) {
      console.log('init map')
      const initMap = L.map('map', {
        center: center,
        maxZoom: 24,
        zoom: 8,
        scrollWheelZoom: false,
        zoomControl: false
      })
      setMap(initMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, map])

  useEffect(() => {
    if(!data) setMarkers(false)
  }, [data])

  useEffect(() => {
    console.log('update data')
    if(updateData) {
      setUpdateData(false)
      getData(center)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData])

  useEffect(() => {
    console.log('location change')
    if(map && location) {
      map.setView(new L.LatLng(parseFloat(location.lat), parseFloat(location.lng)))
      setFitBounds(true)
      setCenter([parseFloat(location.lng), parseFloat(location.lat)])
      setUpdateData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, location])

  useEffect(() => {
    if(markers && markers.length > 0) {
      if(clusters) map.removeLayer(clusters)

      const newClusters = L.markerClusterGroup()
      markers.forEach(m => {
        newClusters.addLayer(m)
      })
      map.addLayer(newClusters)

      setClusters(newClusters)

      if(fitBounds) {
        const features = new L.featureGroup(markers)
        map.fitBounds(features.getBounds())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, markers])

  useEffect(() => {
    async function getMarkers() {
      console.log('set markers')
      setMarkers(false)

      setMarkers(data.filter(doc => {
        if(!doc.lat || doc.lat === 'undefined' || !doc.lng || doc.lng === 'undefined') return false
        return true
      }).map(doc => {
        const position = [parseFloat(doc.lat), parseFloat(doc.lng)]
        const svgIcon = L.divIcon({
          html: `
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
</svg>`,
          className: 'text-primary',
          iconSize: [32, 32],
          iconAnchor: [16, 32],
        })

        const marker = L.marker(position, {
          icon: svgIcon
        })

        const newDiv = document.createElement('div')
        const text = document.createElement('p')
        text.innerHTML = '<p><b>' + doc.firstname + ' ' + doc.lastname + '</b></br>' + doc.formatted_address + '</p>'

        // const button = document.createElement('button')
        // button.appendChild(document.createTextNode('View'))
        // button.onclick = () => {
        //   alert('hi')
        // }
        // button.className = 'font-bold bg-primary px-2 py-1 rounded-full'

        const link = document.createElement('a')
        link.appendChild(document.createTextNode('Hubspot'))
        link.href = 'https://app.hubspot.com/contacts/3446639/contact/' + doc.vid
        link.target = '_blank'
        link.className = 'font-bold bg-primary px-3 py-1 rounded-full !text-black'

        newDiv.appendChild(text)
        // newDiv.appendChild(button)
        newDiv.appendChild(link)

        marker.bindPopup(newDiv).openPopup()

        return marker
      }))
    }

    if(data && map) getMarkers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, map])

  console.log('location', location)

  async function getData(center) {
    console.log('get neighbors')
    setData(false)

    const coordinates = center ? center : [parseFloat(location.lng), parseFloat(location.lat)]
    const sendMessage = httpsCallable(functions, 'mongoQuery')
    await sendMessage({ text: JSON.stringify({
      database: 'astrawatt',
      collection: 'installs',
      find: {
        service_area: location.hubSpotServiceAreaId,
      },
      limit: 0
    })})
    .then(result => {
      setData(result.data)
    })
    .catch(e => {
      console.log(e)
    })
  }

  useEffect(() => {
    if(map && type) {
      let newTiles = false
      if(type.name === 'roadmap-stadia-dark') {
        newTiles = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
         maxZoom: 24,
         maxNativeZoom: 20,
         attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a>'
        }).addTo(map)
      } else if(type.name === 'roadmap-carto') {
        newTiles = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        	attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>',
        	subdomains: 'abcd',
        	maxZoom: 24,
          maxNativeZoom: 20,
        }).addTo(map)
      } else if(type.name === 'roadmap-mapbox-dark') {
        newTiles = L.tileLayer('https://api.mapbox.com/styles/v1/ozpoo/clakgv2x8001016rw2lvudmv0/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
         attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
         tileSize: 512,
         zoomOffset: -1,
         maxZoom: 24,
         maxNativeZoom: 20,
        }).addTo(map)
      } else if(type.name === 'roadmap-mapbox') {
        newTiles = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
         attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
         tileSize: 512,
         zoomOffset: -1,
         maxZoom: 24,
         maxNativeZoom: 20,
        }).addTo(map)
      } else if(type.name === 'roadmap-google') {
        newTiles = L.tileLayer('http://{s}.google.com/vt/lyrsf=m&x={x}&y={y}&z={z}',{
          maxZoom: 24,
          maxNativeZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3'],
          attribution: '&copy; <a href="http://bing.com/maps">Google Maps</a>'
        }).addTo(map)
      } else if(type.name === 'satellite-mapbox') {
        newTiles = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
         attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
         tileSize: 512,
         zoomOffset: -1,
         maxZoom: 24,
         maxNativeZoom: 18,
        }).addTo(map)
      } else if(type.name === 'satellite-usgs') {
        newTiles = L.tileLayer('https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}', {
          maxZoom: 24,
          maxNativeZoom: 20,
        	attribution: '&copy; <a href="https://usgs.gov/">U.S. Geological Survey</a>'
        }).addTo(map)
      } else if(type.name === 'satellite-google') {
        newTiles = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
          maxZoom: 24,
          maxNativeZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3'],
          attribution: '&copy; <a href="http://bing.com/maps">Google Maps</a>'
        }).addTo(map)
      } else if(type.name === 'satellite-bing') {
        async function getBing() {
          const url = 'https://dev.virtualearth.net/REST/V1/Imagery/Metadata/Aerial?output=json&include=ImageryProviders&key='+process.env.REACT_APP_BING_API
          const tiles = await fetch(url)
          const tilesJSON = await tiles.json()

          var BingLayer = L.TileLayer.extend({
            getTileUrl: function (tilePoint) {
                // this._adjustTilePoint(tilePoint);
                return L.Util.template(this._url, {
                    s: this._getSubdomain(tilePoint),
                    q: this._quadKey(tilePoint.x, tilePoint.y, this._getZoomForUrl())
                });
            },
            _quadKey: function (x, y, z) {
                var quadKey = [];
                for (var i = z; i > 0; i--) {
                    var digit = '0';
                    var mask = 1 << (i - 1);
                    if ((x & mask) != 0) {
                        digit++;
                    }
                    if ((y & mask) != 0) {
                        digit++;
                        digit++;
                    }
                    quadKey.push(digit);
                }
                return quadKey.join('');
            }
          })

          const tilesUrl = tilesJSON.resourceSets[0].resources[0].imageUrl.replace('{subdomain}', '{s}').replace('{quadkey}', '{q}')

          newTiles = new BingLayer(tilesUrl, {
            maxZoom: 24,
            maxNativeZoom: 20,
            subdomains: tilesJSON.resourceSets[0].resources[0].imageUrlSubdomains,
            attribution: '&copy; <a href="http://bing.com/maps">Bing Maps</a>'
          }).addTo(map)
        }
        getBing()
      } else if(type.name === 'satellite-arcgis') {
        newTiles = L.tileLayer('http://{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          maxZoom: 24,
          maxNativeZoom: 20,
          subdomains: ['server', 'services'],
          attribution: '&copy; <a href="http://www.arcgis.com/">ArcGIS esri</a>'
        }).addTo(map)
      }

      if(currentTiles.current) map.removeLayer(currentTiles.current)
      currentTiles.current = newTiles
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, type])

  console.log(location)

  return (
    <div className='h-full w-full relative'>
      <div className={'transition duration-200 pointer-events-none absolute z-20 inset-0 bg-gray-200/40 backdrop-blur flex ' + (!markers ? 'opacity-100' : 'opacity-0')}>
        <div className='m-auto text-xl'>
          Loading...
        </div>
      </div>
      <div className='shadow-lg text-sm w-full max-w-xs z-10 m-3 absolute bottom-0 left-0 bg-gray-200 rounded-2xl p-3'>
        {markers &&
          <p className='text-lg font-bold'>{pluralize('installation', markers.length, true)}</p>
        }
        <p>{location.name}</p>
        <p className='truncate'>{location.address}</p>
        <p>{location.phone}</p>
      </div>
      <ul className='m-3 mb-6 absolute bottom-0 right-0 flex flex-col gap-1 z-10'>
        <li>
          <button
            className='shadow-lg flex text-sm bg-white rounded-full h-9 w-9 hover:opacity-80 flex items-center transition duration-200'
            onClick={() => map.setZoom(map.getZoom() + 1)}>
            <PlusIcon className='h-4 w-4 m-auto' strokeWidth={2.5} />
          </button>
        </li>
        <li>
          <button
            className='shadow-lg flex text-sm bg-white rounded-full h-9 w-9 hover:opacity-80 flex items-center transition duration-200'
            onClick={() => map.setZoom(map.getZoom() - 1)}>
            <MinusIcon className='h-4 w-4 m-auto' strokeWidth={2.5} />
          </button>
        </li>
      </ul>
      <div className='absolute top-0 left-0 z-10 m-3 flex gap-2'>
        {locations && <Locations location={location} setLocation={setLocation} locations={locations} />}
        {types && <Types type={type} setType={setType} types={types} />}
      </div>
      <div ref={mapRef} id='map' className='relative h-full w-full z-0' />
    </div>
  )
}

function Types({ type, setType, types }) {
  const ref = useRef(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if(ref.current && !ref.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div ref={ref} className='relative'>
      <button
        onClick={() => {
          setShow(!show)
        }}
        className='flex items-center gap-3 shadow-lg px-4 py-1.5 text-sm rounded-full bg-white'>
        {type.title}
        <ChevronDownIcon className='h-3.5 w-3.5' strokeWidth={2.5} />
      </button>
      {show &&
        <ul className='fade-in py-2 w-screen max-w-xs shadow-lg absolute top-full left-0 mt-3 bg-white rounded-2xl'>
          {types.map(type =>
            <li key={type.name}>
              <button
                className='text-sm px-4 py-2 w-full text-left transition duration-200 hover:bg-gray-1-40'
                onClick={() => {
                  setShow(false)
                  setType(type)
                }}>
                {type.title}
              </button>
            </li>
          )}
        </ul>
      }
    </div>
  )
}

function Locations({ location, setLocation, locations }) {
  const ref = useRef(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if(ref.current && !ref.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div ref={ref} className='relative'>
      <button
        onClick={() => {
          setShow(!show)
        }}
        className='flex items-center gap-3 shadow-lg px-4 py-1.5 text-sm rounded-full bg-primary'>
        {location.name}
        <ChevronDownIcon className='h-3.5 w-3.5' strokeWidth={2.5} />
      </button>
      {show &&
        <ul className='fade-in py-2 w-screen max-w-xs shadow-lg absolute top-full left-0 mt-3 bg-white rounded-2xl'>
          {locations.sort((a,b) => a.name.localeCompare(b.name)).map(loc =>
            <li key={loc.id}>
              <button
                className='text-sm px-4 py-2 w-full text-left transition duration-200 hover:bg-gray-1-40'
                onClick={() => {
                  setShow(false)
                  if(loc.lat && loc.lng) {
                    setLocation(loc)
                  } else {
                    window.alert('Sorry, this location isn\'t finished being set up')
                  }
                }}>
                {loc.name}
              </button>
            </li>
          )}
        </ul>
      }
    </div>
  )
}

const types = [
  // {
  //   name: 'roadmap-stadia-dark',
  //   url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
  //   maxZoom: 20,
  //   attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a>'
  // },
  {
    title: 'Roadmap Light',
    name: 'roadmap-carto',
    url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>',
    subdomains: 'abcd',
    maxZoom: 20
  },
  {
    title: 'Roadmap Dark',
    name: 'roadmap-mapbox-dark',
    url: 'https://api.mapbox.com/styles/v1/ozpoo/clakgv2x8001016rw2lvudmv0/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY,
    attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
    tileSize: 512,
    zoomOffset: -1
  },
  // {
  //   name: 'roadmap-mapbox',
  //   url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY,
  //   attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
  //   tileSize: 512,
  //   zoomOffset: -1
  // },
  // {
  //   title: 'Roadmap Google',
  //   name: 'roadmap-google',
  //   url: 'http://{s}.google.com/vt/lyrsf=m&x={x}&y={y}&z={z}',
  //   maxZoom: 20,
  //   subdomains:['mt0','mt1','mt2','mt3'],
  //   attribution: '&copy; <a href="http://bing.com/maps">Google Maps</a>'
  // },
  {
    title: 'Satellite Mapbox',
    name: 'satellite-mapbox',
    url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY,
    attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
    tileSize: 512,
    zoomOffset: -1
  },
  // {
  //   name: 'satellite-usgs',
  //   url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}',
  //   maxZoom: 20,
  //   attribution: '&copy; <a href="https://usgs.gov/">U.S. Geological Survey</a>'
  // },
  {
    title: 'Satellite Google',
    name: 'satellite-google',
    url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    maxZoom: 20,
    subdomains:['mt0','mt1','mt2','mt3'],
    attribution: '&copy; <a href="http://bing.com/maps">Google Maps</a>'
  },
  {
    title: 'Satellite Bing',
    name: 'satellite-bing',
    get: async (map) => {
      // const url = 'https://dev.virtualearth.net/REST/V1/Imagery/Metadata/Aerial?output=json&include=ImageryProviders&key='+process.env.REACT_APP_BING_API
      // const tiles = await fetch(url)
      // const tilesJSON = await tiles.json()
      //
      // var BingLayer = L.TileLayer.extend({
      //   getTileUrl: function (tilePoint) {
      //       // this._adjustTilePoint(tilePoint);
      //       return L.Util.template(this._url, {
      //           s: this._getSubdomain(tilePoint),
      //           q: this._quadKey(tilePoint.x, tilePoint.y, this._getZoomForUrl())
      //       });
      //   },
      //   _quadKey: function (x, y, z) {
      //       var quadKey = [];
      //       for (var i = z; i > 0; i--) {
      //           var digit = '0';
      //           var mask = 1 << (i - 1);
      //           if ((x & mask) != 0) {
      //               digit++;
      //           }
      //           if ((y & mask) != 0) {
      //               digit++;
      //               digit++;
      //           }
      //           quadKey.push(digit);
      //       }
      //       return quadKey.join('');
      //   }
      // })
      //
      // const tilesUrl = tilesJSON.resourceSets[0].resources[0].imageUrl.replace('{subdomain}', '{s}').replace('{quadkey}', '{q}')
      //
      // newTiles = new BingLayer(tilesUrl, {
      //   maxZoom: 20,
      //   subdomains: tilesJSON.resourceSets[0].resources[0].imageUrlSubdomains,
      //   attribution: '&copy; <a href="http://bing.com/maps">Bing Maps</a>'
      // }).addTo(map)
    }
  },
  // {
  //   name: 'satellite-arcgis',
  //   url: 'http://{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  //   maxZoom: 20,
  //   subdomains: ['server', 'services'],
  //   attribution: '&copy; <a href="http://www.arcgis.com/">ArcGIS esri</a>'
  // }
]

import { Link } from 'react-router-dom'
import { useState, useRef, useEffect } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import { HiEllipsisVertical } from 'react-icons/hi2'

import { tooltipVariants } from 'framer-animations'

import { sections } from 'config'

export default function Index() {
  const ref = useRef(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if(ref.current && !ref.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div ref={ref} className='relative'>
      <button
        onClick={() => {
          setShow(!show)
        }}
        type='button'
        aria-label='Dark mode toggle'
        className='group transition duration-200 h-10 w-10 flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-250'>
        <HiEllipsisVertical strokeWidth={1} className='h-[1.4rem] w-[1.4rem] transition duration-200 group-hover:scale-110' />
      </button>
      <AnimatePresence>
        {show &&
          <div className='absolute top-full right-0 mt-1.5'>
            <motion.div
              className='py-4 bg-gray-200 rounded-xl overflow-hidden w-56 shadow-lg'
              initial='initial'
              animate='in'
              exit='out'
              variants={tooltipVariants}>
              <ul>
                {Object.entries(sections).map(([key, value]) => {
                  return value
                }).map(section => section.paths.map((path, i) =>
                  <li key={'nav-' + path.label + '-' + i}>
                    <Link
                      onClick={() => {
                        setShow(false)
                      }}
                      to={path.path}
                      className='text-sm transition duration-200 px-3 py-3 leading-none rounded-full block hover:bg-gray-250'>{path.label}</Link>
                  </li>
                ))}
              </ul>
            </motion.div>
          </div>
        }
      </AnimatePresence>
    </div>
  )
}

import Logo from 'components/logo/logo'

import { motion } from 'framer-motion'

import Neighborhood from '../components/neighborhood'

import Inc500 from '../components/svg/inc-500.js'
import Tesla from '../components/svg/tesla.js'
import Enphase from '../components/svg/enphase.js'
import Nabcep from '../components/svg/nabcep.js'
import Bbb from '../components/svg/bbb.js'
import Rec from '../components/svg/rec.js'
import Google from '../components/svg/google.js'
import Facebook from '../components/svg/facebook.js'
import SolarPW from '../components/svg/solar-pw.js'
import Osha from '../components/svg/osha.js'
import BestCo from '../components/svg/best-co.js'
import Momentum from '../components/svg/momentum.js'
import RecruitMilitary from '../components/svg/recruit-military.js'
import Kcbj from '../components/svg/kcbj.js'

const why = [
  {
    title: 'Our Focus',
    description: 'Our focus is on improving the sales process by educating homeowners. We do this by helping prospective customers get all the information they need in order to make an educated decision.'
  },
  {
    title: 'Our Process',
    description: 'Our sales process starts with honest and ethical marketing practices. We view our relationships with our customers and their experience working with us as the number one driver of new business.'
  },
  {
    title: 'Vertical Integration',
    description: 'We are one of the few solar companies that is vertically integrated. This means we manage plans design, engineering, permitting, utility net meter applications, and the installation of solar panels — all in-house.'
  },
  {
    title: 'Dedicated Service',
    description: 'Our relationship with our customers does not end when the project is completed and the final check is cashed. We have a dedicated service team who is ready to help with technical support to ensure you yield more solar production for the life of your system and any warranty claims are expedited to minimize downtime.',
  },
  {
    title: 'National Franchise',
    description: 'We are now expanding our services nationally through a franchise model to help more homeowners go solar. This allows our customers to receive better service — and that sets us apart from the rest.'
  }
]
const expertise = [
  {
    image: <Nabcep />,
    text: <span>PV Installation Professional<br /><span className='text-gray-500'>NABCEP Certified</span></span>,
    href: 'https://www.nabcep.org/',
    width: 'w-14'
  },
  {
    image: <Tesla />,
    text: <span>Tesla Partner<br /><span className='text-gray-500'>Powerwall Certified Installer</span></span>,
    href: 'https://www.tesla.com/',
    width: 'w-8'
  },
  {
    image: <Bbb />,
    text: <span>BBB A+ Rating<br /><span className='text-gray-500'>Award from Better Business Bureau</span></span>,
    href: 'https://www.bbb.org/',
    width: 'w-7'
  },
  {
    image: <Kcbj />,
    text: <span>KC's Fastest Growing Companies<br /><span className='text-gray-500'>Kansas City Business Journal</span></span>,
    href: 'https://www.inc.com',
    width: 'w-16'
  },
  {
    image: <Google />,
    text: <span>5 Star Reviews<br /><span className='text-gray-500'>Google Business</span></span>,
    href: 'https://www.google.com/',
    width: 'w-14'
  },
  {
    image: <Inc500 />,
    text: <span>America's Fastest Growing Private Companies<br /><span className='text-gray-500'>Award from Inc 500</span></span>,
    href: 'https://www.inc.com',
    width: 'w-10'
  },
  {
    image: <Facebook />,
    text: <span>5 Star Reviews<br /><span className='text-gray-500'>Facebook Business</span></span>,
    href: 'https://www.facebook.com/',
    width: 'w-14'
  },
  {
    image: <SolarPW />,
    text: <span>Top Solar Contractor<br /><span className='text-gray-500'>Award from Solar Power World</span></span>,
    href: 'https://www.solarpowerworldonline.com/',
    width: 'w-12'
  },
  {
    image: <RecruitMilitary />,
    text: <span>2022 Champion for Military Hiring<br /><span className='text-gray-500'>Award from Recruit Military</span></span>,
    href: '',
    width: 'w-18'
  },
  {
    image: <Osha />,
    text: <span>OSHA Certified<br /><span className='text-gray-500'>Award from OSHA</span></span>,
    href: 'https://www.osha.gov/',
    width: 'w-14'
  },
  {
    image: <BestCo />,
    text: <span>2022 Sustainability Award<br /><span className='text-gray-500'>Award from BestCompany</span></span>,
    href: 'https://bestcompany.com/',
    width: 'w-12'
  },
  {
    image: <Momentum />,
    text: <span>Momentum 100<br /><span className='text-gray-500'>Award from Momentum 100</span></span>,
    href: 'https://mo-summit.com/',
    width: 'w-12'
  },
]

export default function Index({ contact, neighbors }) {
  return (
    <div className='px-6 py-24 flex flex-col items-center'>
      <motion.div
        className='text-center'
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, duration: .4 }}>
        <p className='font-mono uppercase mb-10'>Why Astrawatt</p>
        <h1 className='font-body font-bold text-3xl max-w-3xl mb-24'>We started in 2016 with one mission — to <span className='text-primary'>provide better solar experiences</span> for our customers.</h1>
      </motion.div>

      <ul className='space-y-16 mb-24 max-w-3xl'>
        {why.map((item, index) =>
          <motion.li
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ y: { type: 'spring', stiffness: 100 }, delay: index <= 2 ? .2 : .4, duration: .4 }}
            key={index + '-why-solar'}
            className='flex gap-8'>
            <h2 className='flex-1 font-bold text-2xl'><span className='text-primary'>{String(index + 1).padStart(2, '0')}</span> &mdash; {item.title}</h2>
            <p className='flex-1'>{item.description}</p>
          </motion.li>
        )}
      </ul>

      <Neighborhood contact={contact} neighbors={neighbors} />

      <motion.div
        className='text-center'
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, delay: .4, duration: .4 }}>
        <p className='font-mono uppercase mb-10'>Solar Held to a Higher Standard</p>
        <h1 className='font-body font-bold text-3xl max-w-2xl mb-24'>We are proud to be recognized by our <span className='text-primary'>industry leading</span> partners in solar.</h1>
      </motion.div>

      <motion.ul
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ y: { type: 'spring', stiffness: 100 }, delay: .4, duration: .4 }}
        className='max-w-4xl grid portrait:grid-cols-2 landscape:grid-cols-3 gap-6'>
        {expertise.map((el, index) =>
          <li key={index + '-expertise'} className='flex space-x-2 items-center'>
            <a href={el.href} target='_blank' rel='noopener noreferrer' className='block'>
              <span className='hover:bg-gray-250 relative flex items-center justify-center w-20 h-20 bg-gray-200 overflow-hidden rounded-full'>
                <span className={'relative z-10 ' + el.width}>{el.image}</span>
              </span>
            </a>
            <div className='flex-1 text-sm leading-tight'>{el.text}</div>
          </li>
        )}
      </motion.ul>
    </div>
  )
}

export const pageVariants = {
  initial: {
    opacity: 0,
    y: 10,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
  out: {
    opacity: 0,
    y: 10,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
}

export const tooltipVariants = {
  initial: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.2,
      ease: 'easeOut'
    },
  },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
  out: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.2,
      ease: 'easeOut'
    },
  },
}

export const scaleOutVariants = {
  initial: {
    opacity: 0,
    scale: .9,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
  in: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
  out: {
    opacity: 0,
    scale: .9,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
}

export const fadeVariants = {
  initial: {
    opacity: 0,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
  in: {
    opacity: 1,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
  out: {
    opacity: 0,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
}

export const fadeUpVariants = {
  initial: {
    opacity: 0,
    y: 10,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
  out: {
    opacity: 0,
    y: 10,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
}

export const fadeDownVariants = {
  initial: {
    opacity: 0,
    y: -10,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
  in: {
    opacity: 1,
    y: 0,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
  out: {
    opacity: 0,
    y: -10,
    transition: {
      duration: .4,
      ease: 'easeOut'
    },
  },
}

export const fadeRightVariants = {
  initial: {
    opacity: 0,
    x: -10,
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
  in: {
    opacity: 1,
    x: 0,
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
  out: {
    opacity: 0,
    x: -10,
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
}

export const fadeLeftVariants = {
  initial: {
    opacity: 0,
    x: 10,
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
  in: {
    opacity: 1,
    x: 0,
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
  out: {
    opacity: 0,
    x: 10,
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
}

export const slideLeftVariants = {
  initial: {
    opacity: 0,
    x: '100%',
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
  in: {
    opacity: 1,
    x: '0%',
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
  out: {
    opacity: 0,
    x: '100%',
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
}

export const slideRightVariants = {
  initial: {
    opacity: 0,
    x: '-100%',
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
  in: {
    opacity: 1,
    x: '0%',
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
  out: {
    opacity: 0,
    x: '-100%',
    transition: {
      duration: .2,
      ease: 'easeOut'
    },
  },
}

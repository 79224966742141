import { Link } from 'react-router-dom'
import { useState } from 'react'

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { AnimatePresence } from 'framer-motion'

import LayoutTransition from 'components/transitions/layout'
import TransitionFadeUp from 'components/transitions/fade-up'
import LoaderSpin from 'components/loaders/spin'

import Logo from 'components/logo/logo-lightsale'

export default function Index() {
  const auth = getAuth()

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  function handleSubmit(e) {
    e.preventDefault()
    setLoading(true)
    signInWithEmailAndPassword(auth, email, password).catch((e) => {
      console.log(e)
      console.error('Incorrect username or password')
      if(e.toString().includes('auth/invalid-email')) {
        setError('Invalid email')
      } else if(e.toString().includes('auth/wrong-password')) {
        setError('Wrong password')
      } else if(e.toString().includes('auth/user-not-found')) {
        setError('User not found')
      } else {
        setError(e.toString())
      }
      setLoading(false)
      // auth/invalid-email
      // auth/wrong-password
      // auth/user-not-found
    })
  }

  return (
    <LayoutTransition>
      <AnimatePresence mode='wait'>
        {!loading &&
          <TransitionFadeUp key='login-form'>
            <form
              onSubmit={handleSubmit}
              className='h-screen w-screen flex flex-col gap-6 items-center justify-center'>
              <Logo />
              <div className='w-full max-w-xs space-y-2'>
                <input
                  className='input w-full'
                  type='text'
                  onChange={({ target }) => {
                  setEmail(target.value)
                  }}
                  placeholder='Email'
                />
                <input
                  className='input w-full'
                  type='password'
                  onChange={({ target}) => {
                  setPassword(target.value)
                  }}
                  placeholder='Password'
                />
                {error && <p className='text-center p-3 border-2 border-gray-200 rounded-lg'>{error}</p>}
              </div>
              <button
                type='submit'
                className='text-black font-bold bg-primary hover:brightness-110 rounded-full w-44 py-2'>
                Let's go!
              </button>
              <Link
                to='/forgot-password'
                className='text-sm'>
                Forgot password
              </Link>
            </form>
          </TransitionFadeUp>
        }
        {loading &&
          <TransitionFadeUp key='login-loader'>
            <div className='text-2xl text-gray-250 h-screen w-screen flex items-center justify-center'>
              Loading...
            </div>
          </TransitionFadeUp>
        }
      </AnimatePresence>
    </LayoutTransition>
  )
}

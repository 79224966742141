import { useContext } from 'react'

import { getAuth, sendPasswordResetEmail, sendEmailVerification } from 'firebase/auth'

import { passwordStale } from 'lib/utils'

import { Context } from 'store/index'

export default function Index() {
  const auth = getAuth()
  const [state, dispatch] = useContext(Context)
  return (
    <div className='fade-in flex h-screen w-screen p-3'>
      <div className='flex flex-col sm:flex-row w-full gap-12 max-w-3xl m-auto'>
        {!state.user.emailVerified &&
          <div className='flex-1 text-center space-y-6'>
            <svg xmlns='http://www.w3.org/2000/svg' className='m-auto text-primary w-12 h-12' viewBox='0 0 24 24' fill='currentColor'>
              <path fill-rule='evenodd' d='M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z' clip-rule='evenodd' />
            </svg>
            <p className='font-mono text-sm uppercase tracking-tighter'>Please verify your email address<br />to continue.</p>
            <button
              onClick={() => {
                dispatch({ type: 'toast-set', payload: 'Sending email.' })
                sendEmailVerification(auth.currentUser)
                .then(() => {
                  dispatch({ type: 'toast-set', payload: false })
                  setTimeout(() => {
                    dispatch({ type: 'toast-set', payload: 'Email sent!' })
                  }, 400)
                })
                .catch((error) => {
                  console.log(error.message)
                  dispatch({ type: 'toast-set', payload: error.message })
                })
              }}
              className='font-bold inline-block text-sm rounded-full px-6 py-2 bg-black-800 hover:bg-black-775 active:bg-black-750 hover:text-white'>
              Send verify email
            </button>
            <p className='text-xs text-black-400 leading-tight'>We need to verify your email address to confirm your<br />identity for security purposes.</p>
          </div>
        }
        {state.user && state.user.reloadUserInfo && passwordStale(state.user.reloadUserInfo.passwordUpdatedAt) &&
          <div className='flex-1 text-center space-y-6'>
            <svg xmlns='http://www.w3.org/2000/svg' className='m-auto text-primary w-12 h-12' viewBox='0 0 24 24' fill='currentColor'>
              <path d='M18 1.5c2.9 0 5.25 2.35 5.25 5.25v3.75a.75.75 0 01-1.5 0V6.75a3.75 3.75 0 10-7.5 0v3a3 3 0 013 3v6.75a3 3 0 01-3 3H3.75a3 3 0 01-3-3v-6.75a3 3 0 013-3h9v-3c0-2.9 2.35-5.25 5.25-5.25z' />
            </svg>
            <p className='font-mono text-sm uppercase tracking-tighter'>Please reset your password<br />to continue.</p>
            <button
              onClick={() => {
                dispatch({ type: 'toast-set', payload: 'Sending email.' })
                sendPasswordResetEmail(auth, state.user.email)
                .then(() => {
                  dispatch({ type: 'toast-set', payload: false })
                  setTimeout(() => {
                    dispatch({ type: 'toast-set', payload: 'Email sent!' })
                  }, 400)
                })
                .catch((error) => {
                  console.log(error.message)
                  dispatch({ type: 'toast-set', payload: error.message })
                })
              }}
              className='font-bold text-sm inline-block rounded-full px-6 py-2 bg-black-800 hover:bg-black-775 active:bg-black-750'>
              Send reset email
            </button>
            <p className='text-xs text-black-400 leading-tight'>You must reset your password every 6 months<br />for security purposes.</p>
          </div>
        }
      </div>
    </div>
  )
}

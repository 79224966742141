import { useState, useEffect, useRef, cloneElement } from 'react'
import { createPortal } from 'react-dom'

import sections from '../sections'

import { PlayIcon } from '@heroicons/react/24/solid'
import { XMarkIcon, BookOpenIcon } from '@heroicons/react/24/outline'

import { motion } from 'framer-motion'

export default function Index() {
  const [show, setShow] = useState(false)

  return (
    <div className='mb-14'>
      <motion.button
        whileHover={{
          scale: 1.05
        }}
        whileTap={{ scale: 1 }}
        onClick={() => setShow(!show)} className='intro-video relative bg-gray-100 w-screen max-w-2xl shadow-lg rounded-full'>
        <div className='z-0 aspect-[16/6] flex group w-full relative'>
          <div className='pointer-events-none h-full w-full relative flex-1 relative z-10 flex flex-col justify-center items-center'>
            <div className='h-full w-full absolute top-0 left-0'>
              {/*<iframe
                className='scale-125 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full aspect-square'
                src='https://player.vimeo.com/video/815733113?h=5d89afbcaf&amp;badge=0&amp;title=0&amp;sidedock=0&amp;controls=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                frameBorder='0'
                allow='autoplay; fullscreen; picture-in-picture'
                allowFullScreen
                title='Roger Deines Solar Project'>
              </iframe>*/}
              <img loading='lazy' className='z-0 rounded-2xl overflow-hidden h-full w-full rounded-full object-cover' src='/images/image-01.jpg' />
            </div>
            <div className='relative transition duration-200 h-12 w-12 bg-white rounded-full flex items-center justify-center'>
              <div className='animate-ping absolute top-0 rounded-full left-0 h-full w-full bg-primary-400 z-0' />
              <PlayIcon className='z-10 text-primary h-7 w-7 -mr-1' />
            </div>
            <p className='leading-[1.15] shadow-lg px-6 py-1.5 absolute transform translate-y-1/2 -translate-x-1/2 bottom-0 left-1/2 bg-secondary z-10 text-center rounded-full font-bold text-sm'>Your personal proposal<br />walk-through video.</p>
          </div>
        </div>
        <Modal showModal={show} setShowModal={setShow} />
      </motion.button>
    </div>
  )
}

function Modal({ showModal, setShowModal }) {
  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  return display ? createPortal(
    <div className='fixed z-40 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-lg transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'overflow-hidden flex flex-col h-full w-full transition duration-500 transform ' + (animate ? 'translate-y-0' : 'translate-y-full')}>
        <div className='absolute top-0 right-0 z-10'>
          <div className='p-3'>
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className='group flex h-10 w-10 rounded-full bg-gray-300 hover:bg-gray-350'>
              <XMarkIcon strokeWidth={2.125} className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </button>
          </div>
        </div>
        <div className='p-4 sm:p-6 md:p-8 h-full w-full flex items-center justify-center'>
          <div className='w-full h-60vh rounded-2xl overflow-hidden'>
            <iframe
              className='h-full w-full'
              src='https://player.vimeo.com/video/815733113?h=5d89afbcaf&amp;autoplay=1&amp;badge=1&amp;title=1&amp;sidedock=0&amp;controls=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
              frameBorder='0'
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
              title='Roger Deines Solar Project'>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  , document.body) : null
}
